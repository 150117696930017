<template>
    <Editor
      id="tiny-html-editor"
      v-model="internalValue"
      :api-key="tinyConfig.api_key"
      :init="configWithLanguage"
      :initial-value=initialValue
      @change="handleChange"
      @input="handleInput"
    />
</template>

<script>
import tinyConfig from "@/plugins/tiny"
import Editor from "@tinymce/tinymce-vue"
import vModelMixin from '@/mixins/v-model-mixin'
import {NAMESPACE as LOCATION_NAMESPACE, STATE as LOCATION_STATE} from "@/constants/vuex/location"

export default {
    name: "HTMLEditor",
    components: {Editor},
    mixins: [vModelMixin],
    props: {
      outputFormat: [File, String], // file, text
      initialValue: String,
      maxHeight: {
        type: String,
        default: '600px'
      }
    },

    data() {
        return {
            tinyConfig
        }
    },

    computed: {
        currentLanguage() {
            return this.$store.state[LOCATION_NAMESPACE][LOCATION_STATE.S_CURRENT_LOCATION].code
        },

        configWithLanguage() {
            const config = tinyConfig.init_config
            config.language = this.currentLanguage
            config.language_url = `https://r.mailcomms.io/b2b/tiny/lang/${this.currentLanguage}.js`
            return config
        }
    },

    created() {
        window.addEventListener("editorRendered", () => {
            this.$emit("onRender")
        })
    },
    mounted() {
        document.documentElement.style.setProperty('--editor-max-height', this.maxHeight)
    },

    methods: {
          handleChange(event) {
              this.$emit("change", event)
          },

          handleInput(event) {
              this.$emit("input", event)
          },

          htmlToFile(htmlString, fileName) {
              const blobHtml = new Blob([htmlString], {type: "text/html"})
              return new File([blobHtml], fileName, {type: "text/html"})
          }
      },

    watch: {
        // value(newValue) {
        //   this.editorValue = this.outputFormat === "file" ? this.htmlToFile(newValue, "template.html") : newValue
        // },

        // editorValue() {
        //   this.internalValue = this.outputFormat === "file" ? this.htmlToFile(this.editorValue, "template.html") : this.editorValue
        // }
    }
}
</script>

<style lang="scss">
.tox-tinymce {
  height: var(--editor-max-height) !important;
}

</style>