<template>
    <v-col class="pa-0 pb-9">
        <section-title
          :text="$vuetify.lang.t('$vuetify.shipmentData')"
          icon="reorder"
          idName="data-fields"
        />

        <v-row
          class="ma-0 py-2 pl-2"
          dense
        >
            <p
              v-if="additionalFields"
              id="data_fields-description_additionals"
              class="cts-font-size-4 mb-0 pl-0 mr-9"
            >
                {{$vuetify.lang.t('$vuetify.sectionDataFieldsDescription')}}
            </p>
            <p
              v-else
              id="data_fields-description_not_additionals"
              class="cts-font-size-4 mb-0 pl-2 mr-9"
            >{{$vuetify.lang.t('$vuetify.sectionDataFieldsNotAditionalFields')}}</p>
        </v-row>

        <v-row
          v-if="fieldsNotMapped.length > 0"
          class="ma-0 pl-2"
          dense
        >
            <v-col
              v-for="field in fieldsNotMapped"
              :key="field"
              class="pl-0 pr-3"
              cols="12"
              lg="3"
              md="3"
            >
            <span class="cts-font-size-4">
              {{processFields[field].pretty_print}}
              <span class="cts-color-primary-alternative font-weight-medium">*</span>
            </span>
                <list-data-field
                  v-if="processFields[field].type === 'list'"
                  v-model="internalProcessData[field]"
                  :customid="`data_fields-postal-model-${field}`"
                  :field-name="field"
                  :items="processFields[field].validations.allowed_values"
                />

                <file-data-field
                  v-else-if="['file', 'array_file'].includes(processFields[field].type)"
                  v-model="internalProcessData[field]"
                  :customid="`data_fields-postal-model-${field}`"
                  :field-name="field"
                  :fileTypesAccepted="fieldFileTypesAccepted(field)"
                  :formatA4="fieldFileTypesAccepted(field).includes('application/pdf')"
                  :single-file="processFields[field] === 'file'"
                  :validations="processFields[field].validations"
                  @clear="internalProcessData[field] = ''"
                />

                <TextInput
                  v-else
                  v-model="internalProcessData[field]"
                  :customid="`data_fields-postal-model-${field}`"
                  :maxLength="processFields[field].validations.max_length"
                  :required="true"
                  :requiredText="$vuetify.lang.t('$vuetify.requiredField')"
                />
            </v-col>
        </v-row>

        <v-row
          v-if="processData"
          class="ma-0 pl-2"
          dense
        >
            <v-col
              v-if="processFields.postal_address && mapping['address']"
              class="pl-0 pr-3"
              cols="12"
              lg="4"
              md="3"
            >
        <span class="cts-font-size-4">
          {{processFields.postal_address.pretty_print}}
          <span class="cts-color-primary-alternative font-weight-medium">*</span>
        </span>

                <TextInput
                  v-model="internalProcessData.postal_address"
                  :customid="'data_fields-postal-model-mapped_address'"
                  :maxLength="processFields.postal_address.validations.max_length"
                  :required="true"
                  :requiredText="$vuetify.lang.t('$vuetify.requiredField')"
                />
            </v-col>

            <v-col
              v-if="processFields.zip_code && mapping['zipCode']"
              class="pl-0 pr-3"
              cols="12"
              lg="1"
              md="3"
            >
        <span class="cts-font-size-4 text-truncate">
          {{processFields.zip_code.pretty_print}}
          <span class="cts-color-primary-alternative font-weight-medium">*</span>
        </span>

                <TextInput
                  v-model="zipCode"
                  :customid="'data_fields-postal-model-mapped_zipCode'"
                  :required="true"
                  :requiredText="$vuetify.lang.t('$vuetify.requiredField')"
                  :rules="zipCodeRules"
                  @focusout="onZipCodeFocusOut"
                />
            </v-col>

            <v-col
              v-if="processFields.city && mapping['town']"
              class="px-0 pr-3"
              cols="12"
              lg="3"
              md="3"
            >
        <span class="cts-font-size-4">
          {{processFields.city.pretty_print}}
          <span class="cts-color-primary-alternative font-weight-medium">*</span>
        </span>

                <TextInput
                  v-model="currentTown"
                  :customid="'data_fields-postal-model-mapped_town'"
                  :maxLength="processFields.city.validations.max_length"
                  :required="true"
                  :requiredText="$vuetify.lang.t('$vuetify.requiredField')"
                >
                    <template
                      v-if="towns && towns.length > 1"
                      v-slot:menu
                    >
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  id="data_fields-postal-model-mapped_town_dropdown"
                                  :disabled="towns.length < 2"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                    arrow_drop_down
                                </v-icon>
                            </template>
                            <v-list>
                                <v-list-item
                                  v-for="(item, index) in towns"
                                  :key="index"
                                  class="py-0 my-0 cts-font-size-5"
                                  @click="handleChangeTown(item.city)"
                                >
                                    {{item.city}}
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                </TextInput>

                <v-row
                  v-if="towns && towns.length > 1 && !customCity"
                  class="ma-0 px-1"
                  dense
                >
          <span class="font-weight-medium cts-font-size-5">
            {{$vuetify.lang.t('$vuetify.moreThan1City')}}
          </span>
                </v-row>
            </v-col>

            <v-col
              v-if="processFields.province && mapping['province']"
              class="px-0 pr-3"
              cols="12"
              lg="2"
              md="3"
            >
        <span class="cts-font-size-4">
          {{processFields.province.pretty_print}}
          <span class="cts-color-primary-alternative font-weight-medium">*</span>
        </span>

                <TextInput
                  v-model="currentProvince"
                  :customid="'data_fields-postal-model-mapped_province'"
                  :maxLength="processFields.province.validations.max_length"
                  :required="true"
                  :requiredText="$vuetify.lang.t('$vuetify.requiredField')"
                />
            </v-col>

            <v-col
              v-if="processFields.country && mapping['country']"
              class="px-0 pr-3"
              cols="12"
              lg="2"
              md="1"
            >
        <span class="cts-font-size-4">
          {{processFields.country.pretty_print}}
          <span class="cts-color-primary-alternative font-weight-medium">*</span>
        </span>
                <v-select
                  id="data_fields-postal-model-mapped_country"
                  v-model="internalProcessData.country"
                  :items="countries"
                  class="b2b-postal_model-country-selector"
                  color="primary"
                  dense
                  itemText="name"
                  itemValue="code"
                  outlined
                />
            </v-col>
        </v-row>

        <div v-if="hasFileInput">
            <section-title
              :text="$vuetify.lang.t('$vuetify.communicationContent')"
              icon="description"
              idName="data-fields"
            />

            <v-row
              class="ma-0 py-2 px-0"
              dense
            >
                <p
                  id="data_fields-description_shipment_data"
                  class="cts-font-size-4 mb-0"
                >
                    {{$vuetify.lang.t('$vuetify.shipmentDataSectionDescription')}}
                </p>
            </v-row>

            <v-row
              class="ma-0 py-2"
              dense
            >
                <v-col
                  v-if="fileErrors.length > 0"
                  class="px-0 cts-font-size-4"
                  cols="12"
                >
                    <ul>
                        <li v-for="(messageError,index) in fileErrors" :key="index"
                            class="pa-0 pb-2 b2b-pdf-file-error">
                            <strong>{{messageError.fileError}} </strong> {{messageError.message}}
                        </li>
                    </ul>
                </v-col>

                <v-col
                  v-if="files.length > 0"
                  class="px-0 pb-5"
                  cols="12"
                >
                    <v-row
                      class="ma-0 px-0"
                      dense
                    >
                        <v-col
                          v-for="(file, index) in files"
                          :key="index"
                          align-self="center"
                          class="cts-button-tertiary-block uploaded-file-container pa-2 mr-5 my-2"
                          cols="12"
                          lg="5"
                          md="6"
                          sm="5"
                        >
                            <v-row
                              class="px-0 ma-0"
                              dense
                              justify="space-between"
                            >
                                <v-col
                                  align-self="center"
                                  class="d-inline-block text-truncate ma-0"
                                  style="line-height: 10px;"
                                >
                  <span class="font-weight-medium cts-font-size-5">
                    {{file.name}}
                  </span>
                                </v-col>

                                <v-col
                                  class="text-right d-flex align-center justify-end"
                                  cols="2"
                                >
                                    <v-icon @click="handleFileClick(file)">remove_red_eye</v-icon>
                                </v-col>
                            </v-row>
                        </v-col>

                        <v-col
                          v-if="files.length > 0"
                          align-self="center"
                          cols="12"
                        >
                            <v-icon class="pb-1 pr-1">info</v-icon>
                            <span
                              class="font-weight-medium cts-font-size-5"
                              style="line-height: 10px;"
                            >{{fileResumeText}}</span>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col
                  :class=" $vuetify.breakpoint.smAndDown ? 'justify-center flex-column' :'mr-4'"
                  class="pa-0 d-flex align-center "
                >
                    <standard-button
                      :icon="files.length > 0 ? 'cached' : 'file_upload'"
                      :idExternal="'data_fields-postal-model_addFilesBtn'"
                      :text="files.length > 0 ? $vuetify.lang.t('$vuetify.modifyFiles') : $vuetify.lang.t('$vuetify.uploadFiles')"
                      @click="handleFileInput"
                    />
                    <v-progress-circular
                      v-if="loading"
                      id="data_fields-postal-model_addFilesSpinner"
                      :class="$vuetify.breakpoint.smAndDown ? 'mt-4' :'ml-4'"
                      color="var(--primary-alternative)"
                      indeterminate
                      size="24"
                    />
                </v-col>

                <input
                  id="data_fields-postal-model_addFilesInput"
                  ref="uploader"
                  :multiple="processFields.pdf_file.field_configuration.allow_multiple_selection"
                  accept="application/pdf"
                  class="d-none"
                  type="file"
                  @change="onFileChange"
                >
            </v-row>

            <v-dialog
              v-if="previsualization"
              id="data_preview-dialog"
              v-model="previsualization"
              max-width="1200"
            >
                <v-card
                  class="pa-2"
                  tile
                >
                    <v-card-title class="col-lg-12 pa-0">
                        <dialog-title
                          :close="true"
                          :icon="const_icons.WATCH_FILE"
                          :text="$vuetify.lang.t('$vuetify.previewing') + selectedFile.name"
                          event="closePDFpreview"
                          @close="previsualization=false"
                        />
                    </v-card-title>
                    <v-card-text
                      id="data_preview-dialog-document_content"
                      class="pb-0 mt-4"
                    >
                        <documentsViewer
                          :documentType="selectedFile.type"
                          :file="selectedFile"
                          :fileName="selectedFile.name"
                        />
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>

    </v-col>
</template>

<script>
import SectionTitle from '@/components/sections/processes/processView/sectionTitle'
import TextInput from '@/components/structures/inputs/textInput'
import StandardButton from '@/components/buttons/standardButton'
import {
    readFileContentAsync,
    documentIsGivenExtension,
    nullOrEmpty,
    convertBytesToMegabytes,
} from '@/util/utils'
import const_icons from '@/constants/icons'
import const_global from '@/constants/global'
import DialogTitle from '@/components/structures/dialogTitle'
import DocumentsViewer from '@/components/documentsViewer'
import ZipCodeDB from '@/util/ZipCodeDB'
import {
    NAMESPACE as PROCESSES_NAMESPACE,
    STATE as PROCESSES_STATE,
    ACTIONS as PROCESSES_ACTIONS
} from '@/constants/vuex/processes'
import ListDataField from '@/components/sections/processLaunch/models/commonComponents/dataFields/listDataField'
import FileDataField from '@/components/sections/processLaunch/models/commonComponents/dataFields/fileDataField'
import {getDocumentFormatA4} from '@/services/otherServices'
import {PDFDocument} from "pdf-lib"

export default {
    name: 'ModelPostalDataSection',
    components: {
        SectionTitle,
        TextInput,
        StandardButton,
        DialogTitle,
        DocumentsViewer,
        ListDataField,
        FileDataField
    },
    props: {
        processFields: Object,
        additionalFields: Boolean,
        mapping: Object,
        currentFiles: Object,
        processData: Object
    },

    data() {
        return {
            const_icons,
            files: [],
            previsualization: false,
            selectedFile: null,
            towns: [],
            currentTown: null,
            currentProvince: null,
            zipCode: null,
            countries: require('@/../resources/countryCodes.json'),
            fieldsNotMapped: [],
            fileErrors: [],
            fileErrorsCode: {
                extensionNotAccepted: 'fileExtensionNotAccepted',
                corruptOrProtected: 'filePasswordProtectedOrDamage',
                notSupported: 'processA4NotSupported',
                totalExceedsMaxSize: 'batchModeResourceTableErrorTotalFileSizeLimit'
            },
            loading: false,
            customCity: false,
            zipCodeDBRes: null,
            autocompleteActive: true,
            TOTAL_BYTE_SIZE_LIMIT: const_global.BYTES_LIMIT_18MB,
            totalNumberOfPages: 0,
            maxPages: 389
        }
    },

    computed: {
        internalProcessData: {
            get() {
                return this.processData
            },
            set(value) {
                this.$emit('update:processData', value)
            }
        },
        hasFileInput() {
            return Object.keys(this.processFields).filter(x => ['file', 'array_file'].includes(this.processFields[x]['type'])).length > 0
        },

        totalFilesSize() {
            if (this.files.length === 0) {
                return 0
            }

            return this.files.reduce((acc, x) => acc + x.size, 0)
        },

        fileResumeText() {
            return this.$vuetify.lang.t('$vuetify.fileResume').replace('{nfiles}', this.files.length).replace('{size}', convertBytesToMegabytes(this.totalFilesSize))
        },

        currentTransactionData() {
            return this.$store.state[PROCESSES_NAMESPACE][PROCESSES_STATE.S_CURRENT_TRANSACTION_DATA]
        },

        zipCodeRules() {
            const rules = []
            if (this.mapping['country']) {
                // Internacional
                rules.push(v => (v && v.length <= 20 || this.$vuetify.lang.t('$vuetify.zipCodeFormat')))
            } else {
                rules.push(v => (/^\d{5}$/.test(v) || this.$vuetify.lang.t('$vuetify.zipCodeFormat')))
            }

            return rules
        },

        userCustomValues: {
            get() {
                return this.$store.state[PROCESSES_NAMESPACE][PROCESSES_STATE.S_POSTAL_PROCESS_USER_CUSTOM_VALUES]
            },

            set(value) {
                this.$store.dispatch(`${PROCESSES_NAMESPACE}/${PROCESSES_ACTIONS.A_SET_POSTAL_PROCESS_USER_CUSTOM_VALUES}`, value)
            }
        }
    },

    mounted() {
        this.fieldsNotMapped = Object.keys(this.processFields).filter(x => !Object.values(this.mapping).includes(x))

        if (this.mapping['country']) {
            this.autocompleteActive = false
        }

        if (!this.zipCode && this.currentTransactionData && this.currentTransactionData['zip_code']) {
            this.zipCode = this.currentTransactionData['zip_code']
        }

        if (!this.currentTown && this.currentTransactionData && this.currentTransactionData['city']) {
            this.currentTown = this.currentTransactionData['city']

        }

        if (!this.currentProvince && this.currentTransactionData && this.currentTransactionData['province']) {
            this.currentProvince = this.currentTransactionData['province']

        }
    },

    methods: {
        setCurrentTransactionData(value) {
            this.$store.dispatch(`${PROCESSES_NAMESPACE}/${PROCESSES_ACTIONS.A_SET_CURRENT_TRANSACTION_DATA}`, value)
        },

        fieldFileTypesAccepted(fieldName) {
            switch (fieldName) {
                case 'email_body':
                    return ['text/html']

                case 'email_attachments':
                    return ['application/pdf']
            }
        },

        handleFileInput() {
            this.$refs.uploader.click()
        },

        handleChangeTown(newValue) {
            this.currentTown = newValue
            this.internalProcessData["city"] = newValue
        },

        handleChangeProvince(newValue) {
            this.currentProvince = newValue
            this.internalProcessData["province"] = newValue
        },

        handleFileError(code, file = '', update = {}) {
            const errorMessage = {
                fileError: file.name ? `${file.name} : ` : '',
                message: `${this.$vuetify.lang.t('$vuetify.' + code).replace(update.toChangePart, update.newPart)}`
            }

            errorMessage && this.fileErrors.push(errorMessage);
        },

        async processUploadedPdf(file) {
            const content = await readFileContentAsync(file)
            const validFormatResponse = await getDocumentFormatA4(content)

            const pdfArrayBuffer = await file.arrayBuffer()
            const pdf = await PDFDocument.load(pdfArrayBuffer)

            return {
                validFormatResponse,
                numPages: pdf.getPageCount()
            }

        },

        async onFileChange(e) {
            this.loading = true
            this.fileErrors = []
            this.files = []
            this.$emit('handleChangeTransactionFiles', {pdf_file: []})
            const filesBeforeValidations = []
            let totalSize = 0
            this.totalNumberOfPages = 0

            if (e.target.files.length !== 0) {
                for (const file of e.target.files) {
                    if (file.type === 'application/pdf') {
                        try {
                            const processedPdfInfo = await this.processUploadedPdf(file)
                            totalSize += file.size

                            if (processedPdfInfo.validFormatResponse.data.format_correct && ((processedPdfInfo.numPages + this.totalNumberOfPages) <= this.maxPages)) {
                                filesBeforeValidations.push(file)
                                this.totalNumberOfPages += processedPdfInfo.numPages

                            } else {
                                this.handleFileError(this.fileErrorsCode.notSupported, file)
                            }

                        } catch (error) {
                            this.handleFileError(this.fileErrorsCode.corruptOrProtected, file)
                        }

                    } else {
                        this.handleFileError(this.fileErrorsCode.extensionNotAccepted, file, {
                            toChangePart: '{acceptedExtensions}',
                            newPart: ' Pdf'
                        })
                    }

                }
            }

            totalSize >= this.TOTAL_BYTE_SIZE_LIMIT ?
              this.handleFileError(
                this.fileErrorsCode.totalExceedsMaxSize,
                '',
                {toChangePart: '{size}GB', newPart: `${convertBytesToMegabytes(this.TOTAL_BYTE_SIZE_LIMIT)} MB`}
              )
              : this.files = filesBeforeValidations

            this.loading = false
            this.$emit('handleChangeTransactionFiles', {pdf_file: this.files})
        },

        onZipCodeFocusOut() {
            if (this.processData['zip_code']) {
                this.internalProcessData['zip_code'] = this.internalProcessData['zip_code'].length === 4 ? `0${this.internalProcessData['zip_code']}` : this.internalProcessData['zip_code']
            }
        },

        handleFileClick(file) {
            this.previsualization = true
            this.selectedFile = file
        },

        isPdf(value) {
            return documentIsGivenExtension(value, 'pdf')
        },

        autoCompleteBehaviour(zipCode) {
            if (this.autocompleteActive) {
                this.zipCodeDBRes = ZipCodeDB.getProvinceAndCityName(zipCode)

                if (!nullOrEmpty(this.zipCodeDBRes)) {

                    if (!this.userCustomValues) {
                        this.handleChangeProvince(this.zipCodeDBRes[0]['province'])
                        this.handleChangeTown(this.zipCodeDBRes[0]['city'])
                    }


                    if (!nullOrEmpty(this.currentTown) && this.currentTown !== this.zipCodeDBRes[0]['city'] && !this.userCustomValues) {
                        const newTowns = Object.assign({}, this.zipCodeDBRes)
                        newTowns.unshift({city: this.currentTown})
                        this.towns = newTowns
                    }

                } else {
                    this.handleChangeProvince(null)
                    this.handleChangeTown(null)
                }

                this.towns = this.zipCodeDBRes

                this.internalProcessData['zip_code'] = zipCode
                this.setCurrentTransactionData(this.processData)
            }

        }
    },

    watch: {
        zipCode(newValue) {
            if (/^\d{5}$/.test(newValue) && newValue.length === 5) {
                this.$set(this.processData, "zip_code", newValue)

                if (newValue) {
                    if (!this.userCustomValues) {
                        this.autoCompleteBehaviour(newValue)
                    } else {
                        this.userCustomValues = false
                    }
                }
            } else if (this.mapping['country']) {
                this.$set(this.processData, "zip_code", newValue)
            } else {
                this.$set(this.processData, "zip_code", "")
            }
        },

        processData: {
            handler() {
                if (this.processData['city']) {
                    this.internalProcessData['city'] = this.currentTown
                }

                if (this.processData['province']) {
                    this.$set(this.processData, 'province', this.currentProvince)

                }
                this.setCurrentTransactionData(this.processData)
            },

            deep: true
        },

        currentTown(newValue, oldValue) {
            if (!nullOrEmpty(oldValue) && newValue !== oldValue && (this.towns && !this.towns.map(x => x.city).includes(newValue))) {
                this.userCustomValues = true
            }
            this.handleChangeTown(newValue)

        },

        currentProvince(newValue, oldValue) {
            if (!nullOrEmpty(oldValue) && newValue !== oldValue && (this.towns && !this.towns.map(x => x.province).includes(newValue))) {
                this.userCustomValues = true
            }
            this.handleChangeProvince(newValue)

        },

        files() {
            this.internalProcessData[this.mapping["communicationContent"]] = this.files
            this.$emit("triggerValidateFields")
        }
    }
}
</script>

<style lang="scss">
.uploaded-file-container {
    width: 280px !important;
    border-radius: 4px;
}

.b2b-postal_model-country-selector .v-input__slot {
    height: 28px !important;
}

.b2b-pdf-file-error {
    color: var(--error)
}
</style>