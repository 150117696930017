<template>
  <v-container v-if="!transactionLaunched" class="ma-0 pa-0" fluid>
    <v-row class="px-0 d-lg-none my-2" dense justify="space-between">
      <v-list-item class="pl-0" style="min-width: 70px;">
        <v-list-item-avatar height="50" style="border:solid 1px var(--primary-alternative)" width="50">
          <v-icon color="var(--primary-alternative)" large>{{stepSelected.icon}}</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="cts-font-size-2 font-weight-medium">{{stepSelected.id}}. {{stepSelected.title}}
          </v-list-item-title>
          <v-list-item-subtitle>{{stepSelected.description}}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>

          <v-btn
            v-if="currentStep !== steps.length"
            id="digital-signature-next-button"
            :disabled="checkNextButtonDisabled"
            class="next-button my-auto"
            @click="nextStep()"
          >
            <span class="d-none d-md-inline-block">{{currentStep + 1}}. {{steps[currentStep]?.title}}</span>
            <v-icon>arrow_right</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-row>
    <v-stepper v-model="currentStep" class="ma-0 pa-0" elevation="0" max-width="100%">
      <v-stepper-header class="stepper-header justify-center">
        <v-stepper-step
          v-for="(step) in steps"
          :key="step.id"
          :step="step.id"
          class="ma-0 pa-0 col col-3 d-none d-lg-flex"
        >
          <div class="d-flex flex-row">
            <v-btn
              v-if="step.id !== 1 && step.id === currentStep"
              id="digital-signature-prev-button"
              class="my-auto"
              color="var(--primary-alternative)"
              icon
              @click="prevStep"
            >
              <v-icon size="50">
                keyboard_arrow_left
              </v-icon>
            </v-btn>

            <step-card
              :icon="step.icon"
              :status="getCardStatus(step.id)"
              :subtitle="step.description"
              :title="step.title"
            />
            <v-btn
              v-if="step.id !== steps.length && step.id === currentStep"
              id="digital-signature-next-button"
              :color="step.id === currentStep ? 'var(--primary-alternative)' : 'var(--ternary)'"
              :disabled="step.id !== currentStep || checkNextButtonDisabled"
              class="my-auto"
              icon
              @click="nextStep">
              <v-icon size="50">
                keyboard_arrow_right
              </v-icon>
            </v-btn>
          </div>
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content
          v-for="step in steps"
          :key="step.id"
          :step="step.id"
          class="px-2 px-md-8"
        >
          <component
            :is="step.component"
            :documents="documents"
            :global_transaction="transaction"
            :languages="metadata.languages"
            :transactionLaunched="transactionLaunched"
            :transaction_defaults="overrideDefaultTransaction(transaction_defaults, JSON.parse(metadata.behaviorLayer).steps[0].mapping)"
            @sendTransaction="sendTransaction"
            @validConfigurationForm="handleValidConfigurationForm"
            @validDocumentsSteps="handleValidDocumentsSteps"
            @update:documents="documents = $event"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

    <v-row :justify="currentStep !== 1 ? 'space-between' : 'end'" class="steppers-controls d-none d-lg-flex" dense>
      <v-btn
        v-if="currentStep !== 1"
        id="digital-signature-prev-button"
        class="prev-button"
        @click="prevStep()"
      >
        {{$vuetify.lang.t('$vuetify.back')}}
      </v-btn>

      <v-btn
        v-if="currentStep !== steps.length"
        id="digital-signature-next-button"
        :disabled="checkNextButtonDisabled"
        class="next-button"
        @click="nextStep()"
      >
        {{$vuetify.lang.t('$vuetify.continue')}}
      </v-btn>

    </v-row>
    <v-row :justify="currentStep !== 1 ? 'space-between' : 'end'" class="steppers-controls d-lg-none" dense>
      <v-btn
        v-if="currentStep !== 1"
        id="digital-signature-prev-button"
        class="prev-button-mobile"
        @click="prevStep()"
      >
        {{$vuetify.lang.t('$vuetify.back')}}
      </v-btn>

      <v-btn
        v-if="currentStep !== steps.length"
        id="digital-signature-next-button"
        :disabled="checkNextButtonDisabled"
        class="next-button-mobile"
        @click="nextStep()"
      >
        {{$vuetify.lang.t('$vuetify.continue')}}
      </v-btn>

    </v-row>
  </v-container>

  <SectionTransactionLaunched
    v-else
    :face2face="transaction.type === 'face-to-face'"
    :newDesign="true"
    :transactionName="transaction.name"
  />
</template>

<script>
import StepCard from "@/components/structures/step_launcher/stepCard.vue";
import {
  ACTIONS as PROCESSES_ACTIONS,
  ACTIONS,
  GETTERS as PROCESSES_GETTERS,
  NAMESPACE as PROCESSES_NAMESPACE,
  STATE as PROCESSES_STATE
} from "@/constants/vuex/processes";
import {refreshSession} from "@/services/userServices";
import transactionDefaults from "@/constants/processLaunchModels/digitalTransactiondefaultFields.json";
import {NAMESPACE as AUTHENTICATION_NAMESPACE, STATE as AUTHENTICATION_STATE} from "@/constants/vuex/authentication";
import {
  buildCommunications,
  buildInterveners,
  buildSettings,
  buildSignatures
} from "@/util/digitalSignatureConversor"
import {launchTransaction} from "@/services/processServices"
import SectionTransactionLaunched from "@/components/sections/processes/processView/sectionTransactionLaunched.vue"
import const_global from "@/constants/global";
import {formatFileNameForBack} from "@/plugins/helpers";

export default {
  name: "ProcessDigitalSignatureModel",
  components: {
    SectionTransactionLaunched,
    StepCard,
    DigitalSignatureConfigurationStep: () => import('@/components/sections/processLaunch/models/digitalSignature/steps/DigitalSignatureConfigurationStep'),
    DigitalSignatureSignersStep: () => import('@/components/sections/processLaunch/models/digitalSignature/steps/DigitalSignatureSignersStep'),
    DigitalSignatureDocumentsStep: () => import('@/components/sections/processLaunch/models/digitalSignature/steps/DigitalSignatureDocumentsStep'),
    DigitalSignaturePreviewStep: () => import('@/components/sections/processLaunch/models/digitalSignature/steps/DigitalSignaturePreviewStep'),
  },
  props: {
    processFields: Object,
    extra: Object,
    mapping: Object,
    metadata: Object
  },

  data: () => {
    return {
      currentStep: 1,
      //transaction_default-digitalSignature.json
      transaction_defaults: transactionDefaults,
      transaction: {
        name: '',
        type: 'remote',
        expiration: 30,
        reminders: [],
        global_message: null,
        interveners: [],
        orderEnabled: false, /*Sacar boolean*/
        signatures: [],
      },
      documents: [],
      validConfigurationForm: false,
      validDocumentsSteps: false,
      transactionLaunched: false,
    }
  },
  computed: {
    steps() {
      return [
        {
          id: 1,
          title: this.$vuetify.lang.t('$vuetify.processStepperDigitalSignatureConfigurationTitle'),
          description: this.$vuetify.lang.t('$vuetify.processStepperDigitalSignatureConfigurationDescription'),
          icon: 'settings',
          component: 'DigitalSignatureConfigurationStep'
        },
        {
          id: 2,
          title: this.$vuetify.lang.t('$vuetify.processStepperDigitalSignatureSignersTitle'),
          description: this.$vuetify.lang.t('$vuetify.processStepperDigitalSignatureSignersDescription'),
          icon: 'people',
          component: 'DigitalSignatureSignersStep'
        },
        {
          id: 3,
          title: this.$vuetify.lang.t('$vuetify.processStepperDigitalSignatureDocumentsTitle'),
          description: this.$vuetify.lang.t('$vuetify.processStepperDigitalSignatureDocumentsDescription'),
          icon: 'description',
          component: 'DigitalSignatureDocumentsStep'
        },
        {
          id: 4,
          title: this.$vuetify.lang.t('$vuetify.processStepperDigitalSignatureSummaryTitle'),
          description: this.$vuetify.lang.t('$vuetify.processStepperDigitalSignatureSummaryDescription'),
          icon: 'send',
          component: 'DigitalSignaturePreviewStep'
        }
      ]
    },
    stepSelected() {
      return this.steps.find(s => s.id === this.currentStep)
    },
    checkNextButtonDisabled() {
      return this.currentStep === 2 && this.transaction.interveners.length === 0 ||
        this.currentStep === 1 && !this.validConfigurationForm ||
        this.currentStep === 3 && !this.validDocumentsSteps
    }
  },

  mounted() {
    // Recuperar el estado cuando el componente se monta
    const transactionPending = this.$store.getters[`${PROCESSES_NAMESPACE}/${PROCESSES_GETTERS.G_TRANSACTION_PENDING}`]
    const transactionStep = this.$store.getters[`${PROCESSES_NAMESPACE}/${PROCESSES_GETTERS.G_TRANSACTION_STEP}`]

    if (transactionPending) {
      transactionPending.signatures = []
      this.transaction = transactionPending;
      if (transactionStep) {
        this.currentStep = transactionStep === 4 ? 3 : parseInt(transactionStep, 10);
      }
    } else {
      this.setDefaultValuesForTransaction()
    }
    //window.addEventListener('beforeunload', this.handleBeforeUnload);
  },

  beforeDestroy() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
    this.$store.dispatch(PROCESSES_NAMESPACE + "/" + ACTIONS.A_SET_TRANSACTION_STEP, 0)
    this.$store.dispatch(PROCESSES_NAMESPACE + "/" + ACTIONS.A_SET_TRANSACTION_PENDING, null)
  },

  watch: {
    currentStep(newVal) {
      this.$store.dispatch(PROCESSES_NAMESPACE + "/" + ACTIONS.A_SET_TRANSACTION_STEP, newVal)
    },

    transaction: {
      handler(newVal) {
        this.$store.dispatch(`${PROCESSES_NAMESPACE}/${ACTIONS.A_SET_TRANSACTION_PENDING}`, newVal)
      },
      deep: true
    }
  },

  methods: {
    overrideDefaultTransaction(defaultJson, newJson) {
      for (let key in newJson) {
        if (Object.prototype.hasOwnProperty.call(defaultJson, key)) {
          if (typeof newJson[key] === 'object' && !Array.isArray(newJson[key])) {
            this.overrideDefaultTransaction(defaultJson[key], newJson[key])
          } else {
            defaultJson[key] = newJson[key]
          }
        }
      }
      return defaultJson
    },

    handleBeforeUnload(e) {
      const message = this.$vuetify.lang.t('$vuetify.alertAbandonProcess')
      e.returnValue = message;

      return message;
    },

    setDefaultValuesForTransaction() {
      const mapping = JSON.parse(this.metadata.behaviorLayer).steps[0].mapping
      for (const fieldKey of Object.keys(mapping)) {
        this.transaction[fieldKey] = mapping[fieldKey].default_value;
      }
    },

    getCardStatus(index) {
      if (index < this.currentStep) return 'success';
      if (index === this.currentStep) return 'active';

      return 'disable';
    },

    nextStep() {
      if (this.currentStep <= this.steps.length - 1) {
        this.currentStep++
        refreshSession()
      }
    },

    prevStep() {
      if (this.currentStep > 0) {
        this.currentStep--
        refreshSession()
      }
    },

    handleValidConfigurationForm(isValid) {
      this.validConfigurationForm = isValid
    },

    handleValidDocumentsSteps(isValid) {
      this.validDocumentsSteps = isValid
    },

    async sendTransaction() {
      const billingUnit = this.selectedBillingUnit != null ? this.selectedBillingUnit.code : ""
      const fields = {
        "master_account_code": this.$store.state[AUTHENTICATION_NAMESPACE][AUTHENTICATION_STATE.S_MASTER_ACCOUNT_CODE],
        "process_code": this.$store.state[PROCESSES_NAMESPACE][PROCESSES_STATE.S_CODE],
        "cost_center": billingUnit
      }

      const payload = {
        parameters: fields,
      }

      const csApiInterveners = buildInterveners(
        this.transaction.interveners,
        this.transaction.orderEnabled
      );

      const csApiCommunications = buildCommunications(
        this.transaction.reminders,
        this.transaction.interveners
      );

      const csApiFrontRendererSettings = buildSettings(this.transaction.interveners);

      const documents = this.documents.map(doc => {
        return {
          file_name: formatFileNameForBack(doc.name),
          file_content: doc.file.split(",")[1]
        }
      });
      const csApiSignatures = buildSignatures(this.transaction.signatures);

      payload.parameters['__transaction_name'] = this.transaction.name;
      payload.parameters.expiration = this.transaction.expiration;
      payload.parameters.communications_email = csApiCommunications.communication_email_CS;
      payload.parameters.communications_SMS = csApiCommunications.communication_SMS_CS;
      payload.parameters.interveners = csApiInterveners.interveners;
      payload.parameters.signature_settings = csApiSignatures;
      payload.parameters.front_renderer_settings = csApiFrontRendererSettings;
      payload.parameters.documents = documents;
      payload.parameters.tags_SMS = csApiInterveners.tags_SMS.length < 1 ? ["NO_ENTRY"] : csApiInterveners.tags_SMS;
      payload.parameters.tags_email = csApiInterveners.tags_email.length < 1 ? ["NO_ENTRY"] : csApiInterveners.tags_email;
      payload.parameters.tags_DOC = csApiInterveners.tags_DOC.length < 1 ? ["NO_ENTRY"] : csApiInterveners.tags_DOC;
      payload.parameters.signature_type = this.transaction.type;


      await this.$store.dispatch(`${PROCESSES_NAMESPACE}/${PROCESSES_ACTIONS.A_SET_TRANSACTION_STATUS}`, const_global.TRANSACTION_PROCESSING)
      this.transactionLaunched = true;
      this.documents = [];
      await launchTransaction(payload);
      await this.$store.dispatch(PROCESSES_NAMESPACE + "/" + ACTIONS.A_SET_TRANSACTION_PENDING, null)

    }

  }
}
</script>

<style lang="scss">
.stepper-header {
  justify-content: center;
  box-shadow: none;
  border-bottom: 1px solid var(--card-border);
  height: auto;

  .v-stepper__step {
    padding: 0;
    max-width: 360px;
  }

  .v-stepper__step__step {
    display: none !important;
  }
}

.v-stepper__step__step {
  display: none;
}

.v-stepper__items {
  min-height: 50vh;
}

.steppers-controls {
  border-top: 1px solid var(--card-border);
  padding: 12px;

  .next-button {
    background-color: var(--primary-alternative) !important;
    color: var(--clear);
    width: 240px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14) !important;

  }

  .next-button:hover {
    background-blend-mode: lighten !important;
  }

  .prev-button {
    background-color: var(--tertiary) !important;
    color: var(--clear);
    width: 240px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14) !important;

  }

  .prev-button:hover {
    background-blend-mode: lighten !important;
  }
}

.prev-button-mobile {
  background-color: var(--tertiary) !important;
  color: var(--clear) !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14) !important;
}

.next-button-mobile {
  background-color: var(--primary-alternative) !important;
  color: var(--clear) !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14) !important;
}
</style>