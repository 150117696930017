import {formatFileNameForBack} from "@/plugins/helpers";

export const buildInterveners = (interveners, orderEnabled) => {
  const interveners_CS = [];
  const tags_SMS = []
  const tags_email = []
  const tags_DOC = []

  for (const intervener of interveners) {
    const contact_info = {
      email: [intervener.contact_info.email],
      phone: []
    }

    if (intervener.contact_info.phone.phone_number) {
      contact_info.phone.push({
        phone_number: intervener.contact_info.phone.phone_number,
        country_code: intervener.contact_info.phone.country_code
      })
    }

    const personal_info = {
      name: intervener.personal_info.name,
      identification_document: intervener.personal_info.identification_document
    }
    let tag = intervener.extra_info.id.toUpperCase();


    const files = {signature_documents: "%%documents%%"};
    const metadata = {
      custom_message: ""
    };
    if (intervener.metadata.custom_message) {
      metadata.custom_message = intervener.metadata.custom_message;
    }
    if (intervener.extra_info.custom_message) {
      metadata.custom_message = intervener.extra_info.custom_message
    }
    if (intervener.extra_info.contact_channel === "email") {

      if (intervener.extra_info.final_doc) {
        tags_DOC.push(tag.toUpperCase());
      }

      if (!tags_email.includes(tag.toUpperCase())) {
        tags_email.push(tag.toUpperCase());
      }

      files.email_body = "#+email_template.html"
      files.email_body_reminder = "#+reminder_email_template.html"
      files.email_doc_body = "#+doc_email_template.html"

      metadata.email_subject = "%%+PROCESS.email_subject%%";
      metadata.email_reminder_subject = "%%+PROCESS.email_reminder_subject%%";
      metadata.email_doc_subject = "%%+PROCESS.email_doc_subject%%";

    } else {
      if (!tags_SMS.includes(tag.toUpperCase())) {
        tags_SMS.push(tag.toUpperCase());
      }

      metadata.sms_text = "%%+PROCESS.sms_text%%"
    }

    interveners_CS.push({
      contact_info,
      personal_info,
      locale: [{
        country: "",
        language: intervener.extra_info.language
      }],
      tag: tag.toUpperCase(),
      id: intervener.extra_info.id,
      order: orderEnabled ? intervener.order : 1,
      files,
      metadata
    })
  }

  return {
    interveners: interveners_CS,
    tags_SMS,
    tags_email,
    tags_DOC
  };
}

export const buildCommunications = (reminders, interveners) => {
  const communication_email_CS = [];
  const communication_SMS_CS = [];

  for (const intervener of interveners) {
    const validateCommunication = (communication) => {
      if (channel === "email") {
        return communication_email_CS.findIndex(c => c.time === communication.time) === -1;
      }

      if (channel === "sms") {
        return communication_SMS_CS.findIndex(c => c.time === communication.time) === -1;
      }
    }

    const channel = intervener.extra_info.contact_channel;
    const communication = {time: "0 minutes", step: channel.toUpperCase()};

    // (channel === "email" ? communication_email_CS : communication_SMS_CS).push(communication);

    if (validateCommunication(communication)) {
      channel === "email" ? communication_email_CS.push(communication) : communication_SMS_CS.push(communication);
    }

    for (const reminder of reminders) {
      const reminderCommunication = {
        time: `${reminder.days} days`,
        step: `${channel.toUpperCase()}_REMINDER`
      };

      if (validateCommunication(reminderCommunication)) {
        (channel === "email" ? communication_email_CS : communication_SMS_CS).push(reminderCommunication);
      }
    }
  }

  return {communication_email_CS, communication_SMS_CS};
}

export const buildSignatures = (signatures) => {
  const newSignatures = [];

  for (const signatureObject of signatures) {
    const newAnnotations = signatureObject.annotations.map(signature => {
      const coords = {
        x: signature.position.x / parseFloat(signature.scale),
        y: signature.position.y / parseFloat(signature.scale),
      }

      const position = {
        x: coords.x < 0 ? 0 : coords.x,
        y: coords.y < 0 ? 0 : coords.y,
        width: Math.round(signature.position.width / parseFloat(signature.scale)),
        height: Math.round(signature.position.height / parseFloat(signature.scale)),
        page: signature.position.page
      }

      const settings = signature.settings
      settings.graphometric = signature.type === "signature_graphometric"


      return {
        intervener_id: signature.intervener_id,
        position,
        settings: signature.settings,
        type: "signature"
      }
    });


    const newSignature = {
      document_name: formatFileNameForBack(signatureObject.document_name),
      annotations: newAnnotations
    };

    newSignatures.push(newSignature);
  }

  return newSignatures;
}

export const buildSettings = (interveners) => {
  const front_renderer_settings = [];

  for (const intervener of interveners) {

    let tagName = intervener.extra_info.id.toUpperCase();
    if (!front_renderer_settings.includes(tagName)) {
      const setting = {
        apply_to_tag: tagName,

        models: {
          "skeleton": "model-1",
          "form-identification": "model-1",
          "otp-identification": "model-1",
          "certificate-identification": "model-1",
          "reliable-identification": "model-1",
          "document": "model-1",
          "intervener_finished": "model-1",
          "transaction_finished": "model-1",
          "transaction_canceled": "model-1",
          "in_use": "model-1",
          "waiting_other_interveners": "model-1",
          "identification-document-image": "model-1",
          "transaction_rejected": "model-1",
          "otp-notification": "model-1",
          "signature_conditions_acceptance": "model-1"
        },

        allow_rejection: false,
        allow_delegation: false,
        allow_exceed_number_of_otp: false,
        number_of_otps: 5,
        max_number_of_otps: 10,
        otp_contact_preferences: [],
        otp_validity_seconds: 600,
        otp_regeneration_seconds: 600,
        pdf_render_version: 2,
        prepend_pages: [
          {
            "page_typology": "signature_conditions_acceptance",
            "page_settings": [],
            "unlock_conditions": []
          }
        ],
        require_scroll_to_bottom: intervener.extra_info.config_actions.require_scroll_to_bottom,
        related_documents_tag: "signature_documents",
        signature_popup: true,
        single_click_signature: intervener.extra_info.config_actions.single_click_signature,
        supervisor: false,
        write_rejection_reason: true
      };
      const otp_default_identification = {
        "type": "otp-identification",
        "field": "otp_code",
        "expiration_seconds": 3000
      }

      if (intervener.extra_info.identification_type?.toLowerCase() === "sms_otp" || intervener.extra_info.signature_auth_type === "sms_otp") {
        setting.otp_contact_preferences = ["sms"]
      }
      if (intervener.extra_info.identification_type?.toLowerCase() === "email_otp" || intervener.extra_info.signature_auth_type === "email_otp") {
        setting.otp_contact_preferences = ["email"]
      }

      switch (intervener.extra_info.identification_type?.toLowerCase()) {
        case "nif":
        case "dni":
          setting.identifications = [{
            "type": "form-identification",
            "field": "document_code",
            "expiration_seconds": 3000
          }];
          break;

        case "email_otp":
          setting.identifications = [otp_default_identification];
          break;
        case "sms_otp":
          setting.identifications = [otp_default_identification];
          break;

        case "cert":
        case "digital_certificate":
          setting.identifications = [{
            "type": "certificate-identification",
            "field": "document_code",
            "expiration_seconds": 3000
          }]
          break;

        case "ama":
          setting.identifications = [{
            "type": "ama-identification",
            "field": "document_code",
            "expiration_seconds": 3000
          }]
          break;
        case "video_id":
          setting.identifications = [{
            "type": "identification-document-image",
            "field": "document_code",
            "expiration_seconds": 3600
          }]
          break;
        default:
          setting.identifications = [];
          break;
      }

      front_renderer_settings.push(setting);
    }

  }

  return front_renderer_settings
}
