export default {
    badge: 'Placa',
    close: 'Pechar',
    dataIterator: {
        noResultsText: 'Ningún elemento coincide coa busca',
        loadingText: 'Cargando...'
    },
    dataTable: {
        itemsPerPageText: 'Filas por páxina:',
        ariaLabel: {
            sortDescending: ': Orde descendente. Prema para quitar a orde.',
            sortAscending: ': Orde ascendente. Prema para ordenar de xeito descendente.',
            sortNone: ': Sen orde. Prema para ordenar de xeito ascendente.',
            activateAscending: 'Activar orde ascendente',
            activateDescending: 'Activar orde descendente',
            activateNone: 'Desactivar todo'
        },
        sortBy: 'Ordenado por'
    },
    dataFooter: {
        itemsPerPageText: 'Elementos por páxina:',
        itemsPerPageAll: 'Todos',
        nextPage: 'Páxina seguinte',
        prevPage: 'Páxina anterior',
        firstPage: 'Primeira páxina',
        lastPage: 'Última páxina',
        pageText: '{0}-{1} de {2}'
    },
    datePicker: {
        itemsSelected: '{0} seleccionados',
        prevMonthAriaLabel: 'mes anterior',
        nextMonthAriaLabel: 'mes seguinte'
    },
    noDataText: 'Non hai datos dispoñibles',
    carousel: {
        prev: 'Ver anterior',
        next: 'Ver seguinte',
        ariaLabel: {
            delimiter: 'Carousel slide {0} of {1}'
        }
    },
    calendar: {
        moreEvents: '{0} máis'
    },
    fileInput: {
        counter: '{0} ficheiros',
        counterSize: '{0} ficheiros ({1} en total)'
    },
    timePicker: {
        am: 'AM',
        pm: 'PM'
    },
    home: 'Inicio',
    templates: 'Fluxos',
    accounts: 'Contas',
    processes: 'Procesos',
    transactions: 'Transaccións',
    statistics: 'Estatísticas',
    support: 'Soporte',
    contacts: 'Contactos',
    administration: 'Administración',
    search: 'Busca',
    appearance: 'Estilos',
    users: 'Usuarios',
    alastria: 'Alastria',
    billing: 'Facturación',
    account: 'Xestionar a miña conta',
    admin: 'Administración',
    demos: 'Demostracións',
    selectedSubaccount: 'Subconta seleccionada',
    withoutSubaccount: 'Sen subcontas',
    groups: 'grupos',
    filterDate: 'Filtro de data',
    subaccount: 'Subconta',
    subaccounts: 'Subcontas',
    costCenters: 'Centros de custo',
    subaccountsAssigned: 'Subcontas asignadas',
    defaultCostCenter: 'Centro de custo predeterminado',
    selectedCostCenter: 'Centro de custo seleccionado',
    availableCostCenters: 'Centros de custo dispoñibles',
    changeDefaultCostCenter: 'Cambiar centro de custo predeterminado',
    costCenterDialogText: 'Se o desexa, pode cambiar o centro de custo seleccionado para a transacción. Isto non modificará o centro de custo predeterminado; pola contra, este centro de custo unicamente se usará de xeito puntual para esta transacción',
    costCenterDialogTextAccount: 'Se o desexa, pode cambiar o centro de custo predeterminado. Con esta acción, establecerase o centro de custo seleccionado como predeterminado',
    costCenterSelectedTitle: 'Centro de custo seleccionado',
    activeSubaccount: 'Subconta activa',
    user: 'Usuario',
    userData: 'Datos do usuario',
    userCECOS: 'Centros de custo',
    userCECO: 'Centro de custo',
    userCodeCECO: 'Código',
    userCECOPrimary: 'Predeterminado',
    userCECOCodeTitle: 'Código de centro de custo',
    password: 'Contrasinal',
    passwordchange: 'Cambiar contrasinal',
    oldpassword: 'Contrasinal actual',
    newpassword: 'Novo contrasinal',
    updatePassword: 'Actualizar contrasinal',
    repeatpassword: 'Repetir contrasinal',
    passwordrequirements: 'O contrasinal debe ter polo menos 8 caracteres e cumprir como mínimo 3 destes requisitos adicionais:',
    passwordMinOneLowercase: 'Mínimo 1 letra minúscula.',
    passwordMinOneUppercase: 'Mínimo 1 letra maiúscula.',
    passwordMinOneNumber: 'Mínimo 1 número.',
    passwordMinOneSpecialChar: 'Mínimo 1 carácter especial.',
    passwordMinEightChars: 'Mínimo 8 caracteres.',
    passwordCompares: 'Comprobación de contrasinal requirida.',
    passwordFieldError: 'Erro no campo; consultar requisitos.',
    passwordrequirementsMoreThanEightChars: 'O contrasinal debe ter polo menos 8 caracteres',
    notequalpasswords: 'Os contrasinais non coinciden',
    samepasswordthanolder: 'O novo contrasinal é igual có anterior',
    emptyOldPassword: 'Non introduciu o contrasinal anterior',
    role: 'Rol',
    lastConnection: 'Última conexión',
    languageSelector: 'Seleccione un idioma',
    logout: 'Pechar sesión',
    others: 'Outros',
    continue: 'Continuar',
    continueWSend: 'Continuar envío',
    sendRecoveryForm: 'Enviar solicitude',
    restartFilters: 'Reiniciar filtros',
    back: 'Volver',
    backToMethods: 'Métodos de recuperación',
    moreInfo: 'Máis información',
    activateuser: 'Activar usuario',
    exit: 'Saír',
    platformVersion: 'Versión da plataforma',
    termsAndConditionsCompliance: 'Termos e condicións',
    privacyPoliciesCompliance: 'Política de protección de datos',
    cookiesPoliciesCompliance: 'Política de rastros',
    cookiesPoliciesCertySign: 'Rastros en CertySign',
    alertSizeFiles: 'Todos os ficheiros deben pesar como máximo 8 MB',
    login: 'Iniciar sesión',
    noSubaccountsMessage: 'Non dispón de subcontas asignadas',
    telephone: 'Teléfono',
    name: 'Nome',
    lastName: 'Apelidos',
    email: 'Correo electrónico',
    omit: 'Omitir',
    id: 'Referencia do cliente',
    clear: 'Limpar',
    add: 'Engadir',
    recipients: 'Destinatarios',
    shipmentData: 'Datos do envío',
    prevAndSend: 'Vista previa e envío',
    previewFileSize: 'O ficheiro debe pesar como máximo 15 MB',
    rulesFilesSize: 'Todos os ficheiros deben pesar como máximo 15 MB',
    sectionDataFieldsDescription: 'Na parte inferior aparecen os diferentes campos necesarios para enviar a transacción aos destinatarios. Os campos de contacto encheranse automaticamente a partir dos datos do destinatario',
    sectionDataFieldsNotAditionalFields: 'Neste proceso non hai campos adicionais; finalice o envío da transacción dende a vista previa.',
    sectionPreviewSubtitle: 'Na parte inferior pode pasar dun destinatario a outro para comprobar individualmente cada envío',
    manual_version: 'Amosando a última versión do manual de usuario.',
    view_login: 'Iniciar sesión',
    view_transactions: 'Transaccións',
    view_transaction: 'Detalles da transacción',
    view_process: 'Executar proceso',
    view_processes: 'Procesos',
    view_statistics: 'Estatísticas',
    view_support: 'Axuda e soporte',
    view_administration: 'Administración',
    view_groups: 'Grupos',
    view_group: 'Detalles do grupo',
    view_users: 'Usuarios',
    view_account: 'A miña conta',
    view_remember_password: 'Lembrar contrasinal',
    view_change_password: 'Cambiar contrasinal',
    view_activate_user: 'Activar usuario',
    view_new_password: 'Novo contrasinal',
    view_contacts: 'Contactos',
    view_user: 'Detalles do usuario',
    view_withoutsubaccounts: '',
    view_forbidden: '',
    view_root: '',
    view_error500: 'Erro',
    searchAgain: 'Volver buscar',
    recipient: 'Destinatario',
    sender: 'Remitente',
    namesender: 'Nome do remitente',
    subject: 'Asunto',
    emailbody: 'Corpo do correo electrónico',
    resourcespreview: 'Vista previa dos recursos',
    attach: 'Anexar',
    actions: 'Accións',
    changePassword: 'Actualizar contrasinal',
    sessionClosed: 'Sesión pechada correctamente',
    noPermissionMessage: 'Non ten permisos para acceder a este apartado',
    download: 'Descargar',
    viewDocument: 'Ver documento',
    accept: 'Aceptar',
    reject: 'Rexeitar',
    change: 'Cambiar',
    addVariables: 'Engadir variable',
    resources: 'Recursos',
    launchDataKo: 'Os datos fornecidos non son válidos para realizar o envío',
    launchDataOk: 'Os datos son correctos; pode proceder co envío',
    addNewRecipient: 'Engadir novo destinatario',
    transactionRecipients: 'Destinatarios da transacción',
    noRecipientsSelected: 'Ningún destinatario seleccionado',
    recipientsSelected: 'Seleccionáronse',
    recipientSelected: 'Seleccionouse',
    preview: 'Vista previa',
    summary: 'Resumo',
    transaction_summary: 'Resumo do envío',
    PDFpreview: 'Consultar a vista previa do PDF',
    previewing: 'Consultando a vista previa: ',
    notAvailable: 'Non dispoñible',
    hide: 'Ocultar',
    send: 'Enviar',
    showFile: 'Ver ficheiro',
    error500Message: 'Ocorreu un erro. Se persiste o problema, pode contactar con soporte a través deste ',
    thisForm: 'formulario',
    error401Message: 'A sesión caducou',
    withoutData: 'Sen datos',
    notInformed: 'Non fornecido',
    loginTitleText: 'Iniciar sesión',
    changePasswordTitleText: 'Cambio de contrasinal',
    activateUserTitleText: 'Activación de usuario',
    homeWelcomeMessageTitle: 'Dámoslle a benvida a CertySign',
    homeWelcomeMessageSubtitle: 'Contorno de xestión de transaccións e fluxos de entrega certificada',
    changePasswordUserTitleText: 'Establecer novo contrasinal',
    activateUserSubtitleText: 'Para activar o usuario, é necesario introducir un contrasinal novo',
    changePasswordUserSubtitleText: 'Para establecer un contrasinal novo, encha os campos',
    activateUserTitleTextWithoutHash: 'URL non válida',
    activateUserSubtitleTextWithoutHash: 'A URL que está utilizando non é válida. Se o desexa, pode solicitar unha nova URL mediante a opción "Esqueceume o contrasinal" da pantalla de inicio de sesión.',
    goToLogin: 'Inicio de sesión',
    activeFilters: 'Filtros activos',
    rememberPassword: 'Esqueceulle o contrasinal?',
    rememberPasswordTitleText: 'Recuperar contrasinal',
    supportContact: 'Contacto con soporte',
    rememberPasswordSubTitleText: 'Seleccione un método para recuperar o contrasinal',
    rememberPasswordEmailResponse: 'Se o usuario fornecido está dado de alta, recibirá un correo electrónico coas instrucións para cambiar o contrasinal',
    rememberPasswordWebResponse: 'Se os datos fornecidos son correctos, o noso equipo de soporte contactará con vostede por correo electrónico para darlle instrucións para cambiar o contrasinal',
    rememberPasswordPhoneResponse: 'Se os datos fornecidos son correctos, o noso equipo de soporte contactará con vostede por teléfono para darlle instrucións para cambiar o contrasinal',
    expiredPasswordResponse: 'O seu contrasinal caducou; en breve recibirá un correo electrónico con instrucións para establecer outro novo',
    rememberPasswordSupportEmail: 'Correo electrónico de recuperación',
    rememberPasswordSupportEmailSubtitle: 'Enviarémoslle un correo electrónico dende o que poderá recuperar o contrasinal',
    rememberPasswordSupportEmailText: 'Debe escribir no formulario o correo electrónico que está rexistrado en CertySign',
    rememberPasswordSupportWeb: 'Soporte a través de Internet',
    rememberPasswordSupportWebSubtitle: 'Enviar un correo electrónico ao equipo de soporte técnico de CertySign',
    rememberPasswordSupportWebText: 'Encha os datos e o equipo de soporte técnico responderá o antes posible.',
    emailSupportServiceHours: 'Horarios de atención:',
    rememberPasswordSupportPhone: 'Soporte telefónico',
    rememberPasswordSupportPhoneSubtitle: 'Chamada telefónica ao equipo de soporte técnico de CertySign',
    rememberPasswordSupportPhoneText: 'Encha os datos e o equipo de soporte técnico chamarao/a o antes posible.',
    phoneSupportServiceHours: 'Horario de soporte telefónico:',
    rememberPasswordNotSupportBySchedule: 'Opción inhabilitada por estar fóra do horario',
    rememberPasswordMondayToFriday: 'Luns a venres:',
    rememberPasswordSaturdaySundayAndOthers: 'Sábados, domingos e festivos:',
    rememberPasswordNotIncludedDays: '(festivos non incluídos)',
    supportFinalSendMessage: 'Enviouse a súa solicitude ao noso departamento de soporte',
    goBackToMethods: 'Seleccionar outro método',
    rememberPasswordContactName: 'Nome e apelidos',
    goToC2C: 'Solicitar chamada',
    messageDescription: 'Mensaxe',
    company: 'Empresa',
    legalCheckboxText: 'Acepto as condicións de uso da páxina e a política de protección de datos',
    selectHelps: 'Como lle podemos axudar?',
    selectHelpsTooltip: 'Axuda e soporte',
    helpManual: 'Ver manual',
    helpGuide: 'Ver guía rápida',
    helpSupport: 'Contactar con soporte',
    helpSupportPhonePreffered: 'Contactar preferentemente por teléfono',
    helpSupportPhoneSchedule: 'Horario de atención telefónica, laborables',
    requiredEmail: 'Correo electrónico requirido',
    requiredPhone: 'Teléfono requirido',
    requiredName: 'Nome requirido',
    requiredEmailFormat: 'Formato de correo electrónico incorrecto',
    requiredPhoneFormat: 'Formato de teléfono incorrecto',
    requiredTextarea: 'Mensaxe requirida',
    tag_signature: 'Sinatura electrónica',
    tag_email: 'Correo electrónico',
    tag_sms: 'SMS',
    tag_cert_email: 'Correo electrónico certificado',
    tag_landing: 'Páxina web',
    tag_biometric_signature: 'Sinatura biométrica',
    tag_postal: 'Postal',
    processesViewListTitle: 'Procesos',
    processesViewListDescription: 'Lista de todos os procesos dispoñibles en CertySign',
    tags: 'Etiquetas',
    searchs: 'Buscar',
    processInfo: 'Información do proceso',
    processesCategoriesFilter: 'Filtro por categorías',
    processesTagFilter: 'Filtro por etiquetas',
    processesTagOthers: 'outros',
    processesSearch: 'Buscar procesos',
    searchTransactions: 'Consultar transaccións',
    searchTransaction: 'Consultar transacción',
    deleteFilters: 'Borrar filtros',
    withoutResults: 'Sen resultados',
    withoutProcessResults: 'Non dispón de procesos para executar segundo os seus permisos ou os criterios de busca establecidos',
    allProcesses: 'Todos os procesos',
    emailNotValid: 'O correo electrónico non é válido',
    phoneNotValid: 'O teléfono non é válido',
    phoneAtLeastNineChars: 'O teléfono debe ter polo menos 9 números',
    doesntExist: 'Non existe',
    dontExist: 'Non existen',
    requiredField: 'Campo requirido',
    errorField: 'Campo incorrecto',
    numberField: 'Campo numérico',
    fiveCharsField: 'O campo debe ter 5 caracteres',
    CPvalidField: 'CP non se corresponde coa provincia',
    delete: 'Eliminar',
    save: 'Gardar',
    file: 'Ficheiro',
    loading: 'Cargando',
    countrySelector: 'Seleccione un país',
    provinceSelector: 'Seleccione unha provincia',
    andNumberMore: 'e {count} máis',
    selectFile: 'Seleccione un ficheiro',
    selectFileHtml: 'Seleccione un ficheiro html',
    selectFiles: 'Seleccione ficheiros',
    allTransactions: 'Todas as transaccións',
    transactionsPerPage: 'Transaccións por páxina',
    transactionsSend: 'Enviáronse',
    transactionslower: 'transaccións',
    transactionWithoutId: 'Asignación de ID pendente',
    withoutCertificated: 'Certificado non dispoñible',
    filter: 'Filtrar',
    transactionsProcessFilter: 'Filtro por proceso',
    processDataPreviewText: 'A continuación, pode atopar o resumo da nova transacción, incluídos o número de envíos necesarios e o centro de custo asociado',
    processDataPreviewTextWithoutCC: 'A continuación, pode atopar o resumo da nova transacción, onde se indica o número de envíos necesarios',
    transactionsLinkedGroupsFilter: 'Filtro por grupo',
    transactionsDateFilter: 'Filtro por data',
    numberOfTransactions: 'Número de envíos',
    associatedCECO: 'Centro de custo asociado',
    transactionsCategoryFilter: 'Filtro por categoría',
    transactionsStatusFilter: 'Filtro por estado',
    transactionState: 'Estado da transacción',
    transactionSummary: 'Resumo da transacción',
    transactionGeneralData: 'Información da transacción',
    transactionData: 'Datos da transacción',
    files: 'Ficheiros',
    transactionContact: 'Datos do contacto',
    transaction: 'Transacción',
    transactionEvents: 'Eventos certificables da transacción',
    transactionWithoutEvents: 'Non hai eventos rexistrados para a transacción',
    transactionProgress: 'Progreso da transacción',
    transactionSubtitle: 'Información do estado da transacción',
    transactionDetails: 'Detalles da transacción',
    transactionEventInfo: 'Información do evento',
    eventMeaningPrepend: 'Que significa o evento ',
    eventMeaningAppend: '?',
    eventMeaning: 'Que significa este evento?',
    transactionLaunchedDate: 'Data de creación',
    transactionLastEvent: 'Último evento recibido',
    transactionLastEventDate: 'Data do último evento',
    transactionContactName: 'Nome e apelidos',
    transactionContactMobilephone: 'Teléfono móbil',
    transactionContactEmail: 'Correo electrónico',
    transactionContactID: 'ID',
    advancedFilters: 'Filtros avanzados',
    group: 'Grupo',
    date: 'Data',
    hideFilters: 'Ocultar filtros',
    processName: 'Nome do proceso',
    usernameTransactionDetail: 'Usuario remitente',
    recipientsNumber: 'Número de destinatarios',
    invalidTransaction: 'Datos non válidos',
    dataTableShowRecipients: 'Visualizando envío <span class="font-weight-medium">:page:</span> de <span class="font-weight-medium">{total}</span>',
    transactionRedirectToProcesses: 'Ir a procesos',
    transactionRedirectToTransactions: 'Ir a transaccións',
    transactionRedirectToNewSend: 'Novo envío',
    transactionProcessed: 'Transacción procesada correctamente',
    transactionsProcessed: 'Transaccións procesadas correctamente',
    transactionError: 'Transacción procesada con erros',
    transactionsError: 'Transaccións procesadas con erros',
    transactionProcessing: 'Procesando transacción...',
    transactionLaunchedMessage: 'A transacción procesouse correctamente; a medida que se vaian procesando os envíos, aparecerán no apartado de',
    transactionsLaunchedMessage: 'As transaccións procesáronse correctamente; a medida que se vaian procesando os envíos, aparecerán no apartado de',
    transactionDownloadFile: 'Ver certificado',
    transactionProcessingMessage: 'O sistema está procesando a transacción; espere un intre',
    transactionsProcessingMessage: 'O sistema está procesando as transaccións; espere un intre',
    transactionsErrorMessage: 'Durante o procesamento das transaccións dos distintos destinatarios, detectáronse datos non válidos en polo menos unha transacción. A continuación aparece a lista completa de transaccións do envío',
    transactionErrorMessage: 'Durante o procesamento da transacción, detectáronse datos non válidos',
    recoverProcessQuestion: 'Se comeza un proceso novo, perderá os avances',
    recoverProcessQuestionAppend: 'Desexa comezar un proceso novo?',
    statisticsTransactionsMultipleTitle: 'Transaccións realizadas entre os días <span class="font-weight-bold">{start_day} de {start_month}</span> y <span class="font-weight-bold">{end_day} de {end_month}</span>',
    statisticsTransactionsOneTitle: 'Transaccións realizadas o día {start_day} de {start_month}',
    statisticsTotalTransactionsTitle: 'Totais por tipo de transacción',
    statisticsNoResults: 'Non hai resultados que coincidan cos filtros indicados',
    statisticsProcessTitle: 'Proceso:',
    total: 'Total',
    totalTransactions: 'Transaccións totais',
    totalTransactionsByProcesses: 'Detalle de transaccións por procesos',
    accountDescription: 'Configuración de conta',
    accountTitle: 'Conta',
    contactsDescription: 'Lista e xestión de contactos',
    contactsTitle: 'Contactos',
    transactionsDescription: 'Lista e detalles de transaccións',
    transactionsTitle: 'Transaccións',
    statisticsDescription: 'Análise gráfica das súas transaccións',
    statisticsTitle: 'Estatísticas',
    supportDescription: 'Neste apartado ofréceselle axuda a través do soporte técnico ou o manual de usuario',
    supportManualDescription: 'Neste apartado pode consultar a documentación relacionada co manexo da plataforma',
    supportManualTitle: 'Consultar manual e guía',
    supportManualTitleOnlyManual: 'Consultar manual',
    supportManualTitleOnlyGuide: 'Consultar guía',
    administrationDescription: 'Administración de contas da plataforma',
    administrationTitle: 'Administración',
    userActive: 'Usuario activo',
    userInactive: 'Usuario desactivado',
    userStateTitle: 'Estado do usuario',
    groupsTitle: 'Grupos de permisos',
    groupsDescription: 'Xestión dos grupos da conta. Configuración dos parámetros e permisos de cada grupo.',
    groupsAssignation: 'Grupo asignado',
    groupInheritFrom: 'Herdar permisos de',
    groupDataTitle: 'Información do grupo',
    groupUsersPermissionsTitle: 'Permisos, usuarios e procesos',
    groupPermissionsTabsTitle: 'Permisos',
    groupProcessesAndSubaccounts: 'Subcontas e procesos',
    groupLinked: 'Grupos vinculados',
    groupPermissionsOwnerText: 'Este grupo é o propietario da conta, ten asignados todos os permisos',
    groupPermissionsOwnerSubaccountProcessesText: 'Este grupo é o propietario da conta, ten asignadas todas as subcontas e procesos',
    groupAssociatedProcesses: 'Procesos asociados',
    groupAssociatedSubaccounts: 'Subcontas asociadas',
    newGroup: 'Novo grupo',
    groupName: 'Nome do grupo',
    editGroup: 'Editando grupo',
    groupDescription: 'Descrición',
    groupMinDaysToChange: 'Días mínimos de vixencia do contrasinal',
    groupTabUsersNameTitle: 'Nome e apelidos',
    groupTabPermissionSectionTitle: 'Apartados',
    groupTabPermissionGroupTitle: 'Grupos',
    groupTabPermissionUsersTitle: 'Usuarios',
    usersSearch: 'Buscar usuarios',
    subaccountWithoutProcesses: 'Subconta sen procesos asociados',
    selectCostCenter: 'Seleccione un centro de custo',
    adminGroupInfoDescription: 'Neste apartado pode editar a descrición, as políticas de sesións e os contrasinais da plataforma.',
    adminGroupInfoUsers: 'Neste apartado pode xestionar os usuarios asociados a este grupo.',
    adminGroupInfoPerms: 'Neste apartado pode xestionar os permisos relacionados coas funcións que o usuario pode realizar na plataforma.',
    adminGroupInfoProcesses: 'Neste apartado pode xestionar o acceso do usuario a procesos e subcontas. Este acceso outorgaralle permisos de execución, visualización das transaccións e análise estatística dos procesos asignados.',
    adminGroupInfoLinked: 'Neste apartado pode xestionar a supervisión doutros grupos. Os grupos que estean habilitados compartirán a información das transaccións executadas e as estatísticas co grupo actual.',
    passwordsQuantity: 'Número de contrasinais almacenados',
    passwordExpiration: 'Días para que caduque o contrasinal',
    loginRetriesQuantity: 'Número de intentos de inicio de sesión',
    loginRetriesTime: 'Minutos para volver intentar iniciar sesión',
    sessionExpiration: 'Minutos para que caduque a sesión',
    processAlertExpirationQuestion: 'Desexa continuar?',
    processAlertExpirationPhrase: 'Por motivos de seguridade, a sesión caducará en breve',
    processAlertExpiration: 'Alerta de caducidade',
    continueSession: 'Continuar',
    usersTitle: 'Usuarios',
    activate: 'Activado',
    deactivate: 'Desactivado',
    usersPerPage: 'Usuarios por páxina',
    usersDescription: 'Xestión dos usuarios da conta',
    userInfoTitle: 'Información do usuario',
    userPermissionTitle: 'Tipo de permiso asignado',
    newUser: 'Novo usuario',
    editUser: 'Editando usuario',
    groupTitle: 'Nome do grupo',
    administrator: 'Administrador',
    groupLinkedHeader: 'Vinculado',
    firmaElectronica: 'Sinatura electrónica',
    comunicacionCertificada: 'Comunicación certificada',
    publicacionWeb: 'Publicación web',
    groupVisibility: 'Visibilidade de grupo',
    passwordPolicy: 'Política de contrasinais',
    frontalPolicy: 'Política de sesións',
    groupData: 'Datos do grupo',
    groupsPerPage: 'Grupos por páxina',
    groupsFound: 'Atopáronse',
    cancel: 'Cancelar',
    description: 'Descrición',
    subaccountChanged: 'Subconta cambiada',
    processContinueTitle: 'Ten un proceso pendente de envío',
    processContinueSubtitle: 'Desexa recuperar o proceso ou iniciar outro novo?',
    newProcess: 'Novo proceso',
    recover: 'Recuperar',
    newShipping: 'Novo envío',
    toContinueFillFields: 'Para continuar, encha correctamente os campos',
    passwordRequired: 'Contrasinal requirido',
    userRequired: 'Usuario requirido',
    fieldAlert: 'O campo %%fields%% encherase automaticamente a partir dos destinatarios',
    fieldAlertPlural: 'Os campos %%fields%% encheranse automaticamente a partir dos destinatarios',
    fieldNumberPhone: 'teléfono',
    fieldCountryCode: 'código de país',
    fieldEmail: 'correo electrónico',
    fieldName: 'nome',
    fieldSurname: 'apelidos',
    and: 'e',
    certificate: 'Certificado',
    country: 'País',
    continueEditing: 'Editar os datos do envío',
    second: 'segundo',
    seconds: 'segundos',
    nextRefreshAvailableAt: 'Próxima actualización dispoñible:',
    fastSearch: 'Busca rápida',
    update: 'Actualizar',
    process: 'Proceso',
    transactionId: 'ID da transacción',
    transactionClientId: 'Referencia do cliente',
    createdDate: 'Creada',
    updatedAt: 'Actualizada',
    state: 'Estado',
    noGroupAssigned: 'Sen grupo seleccionado',
    contact_country_code: 'Código de país',
    contact_email: 'Correo electrónico',
    contact_id: 'Referencia do cliente',
    contact_name: 'Nome',
    contact_phone: 'Teléfono',
    contact_surname: 'Apelidos',
    contact_complete_name: 'Nome completo',
    certificateValidDownload: 'Certificado descargado correctamente',
    certificateFailDownload: 'Non se puido descargar o certificado',
    graphicBarValidDownload: 'Gráfica descargada correctamente',
    graphicBarFailDownload: 'Non se puido descargar a gráfica',
    //events
    queued: 'A transacción está pendente de procesarse.',
    new_communication: 'A transacción xerouse correctamente.',
    orchestrator_new_communications_communication_received: 'A transacción procesouse correctamente.',
    commstracker_generate_url_generated: 'Xerouse a ligazón da URL acurtada.',
    commstracker_generate_url_click: 'Abriuse a ligazón da transacción.',
    communication_send_sms_sent: 'Depositouse a mensaxe de texto no centro de servizo de envío do provedor. Este, pola súa parte, xestionará o envío ao destinatario final.',
    communication_send_sms_not_sent: 'A mensaxe de texto non puido depositarse no centro de servizo de envío do provedor.',
    communication_send_sms_delivered: 'A mensaxe de texto entregouse ao destinatario.',
    communication_send_sms_not_delivered: 'A mensaxe de texto non puido entregarse ao destinatario.',
    generate_otp_code_otp_generated: 'Xerouse unha clave dun só uso (OTP) para utilizala.',
    generate_certificate_generated: 'Xerouse o certificado de finalización da transacción, o cal inclúe o contido da comunicación e as evidencias asociadas aos eventos acontecidos durante o proceso.',
    communication_send_email_delivered: 'Entregouse a mensaxe ao provedor do servizo de correo electrónico (ESP) do destinatario.',
    communication_send_email_complaint: 'O destinatario da mensaxe marcou o correo electrónico recibido como non desexado.',
    communication_send_email_queued: 'A mensaxe de correo electrónico púxose en cola.',
    communication_send_email_deferred: 'A mensaxe de correo electrónico púxose en cola e entregarase cando sexa posible.',
    communication_send_email_bounce: 'A mensaxe de correo electrónico rebotou.',
    communication_send_email_sent: 'Depositouse a mensaxe de correo electrónico no servizo de envío do provedor. Este, pola súa parte, xestionará o envío ao destinatario final.',
    communication_send_email_sender_not_allowed: 'A mensaxe de correo electrónico non se puido enviar por non estar autorizado o remitente.',
    communication_send_email_open: 'O destinatario abriu a mensaxe de correo electrónico.',
    communication_send_email_click: 'O destinatario da mensaxe de correo electrónico premeu nunha ligazón.',
    communication_send_email_block: 'O enderezo de correo electrónico do destinatario está na lista de exclusións, polo que non se enviou a mensaxe.',
    communication_send_email_unsubscribe: 'O destinatario da mensaxe de correo electrónico quere darse de baixa da lista de distribución.',
    communication_send_email_spam: 'A mensaxe de correo electrónico identificouse como non desexada.',
    communication_send_email_hard_blacklisted: 'O enderezo de correo electrónico do destinatario está na lista de exclusións, polo que non se enviou a mensaxe.',
    communication_send_email_complaint_blacklisted: 'O enderezo de correo electrónico do destinatario está na lista de queixas, polo que non se enviou a mensaxe.',
    document_upload_s3_uploaded: 'Almacenouse de xeito seguro o certificado de finalización da transacción, o cal inclúe o contido da comunicación e as evidencias asociadas aos eventos acontecidos durante o proceso.',
    communication_send_telegram_delivered: 'A mensaxe de texto entregouse ao destinatario.',
    communication_send_telegram_not_delivered: 'A mensaxe de texto non puido entregarse',
    web_framework_generated: 'Xerouse a interface gráfica web para que o destinatario poida interactuar.',
    web_framework_solicitud_otp: 'Solicitouse unha clave dun só uso (OTP).',
    web_framework_signable_document_loaded: 'Cargouse na interface web un documento que hai que asinar.',
    web_framework_otp_simple_signature_ok: 'Asinouse a documentación mediante a clave dun só uso.',
    web_framework_commited: 'Finalizouse o proceso na interface web.',
    web_framework_document_signed: 'Asinouse a documentación.',
    web_framework_form_based_identification: 'Finalizouse o paso de autenticación mediante formulario.',
    web_framework_certificate_based_identification: 'Produciuse un acceso correcto con certificado persoal',
    web_framework_document_based_identification: 'Identificouse no formulario de xeito correcto.',
    web_framework_certificate_based_identification_failed: 'Fallou o proceso de identificación mediante certificado.',
    web_framework_document_based_identification_failed: 'Fallou o proceso de identificación mediante documento.',
    web_framework_form_based_identification_failed: 'Fallou o proceso de autenticación mediante formulario.',
    web_framework_web_framework_expired: 'A transacción caducou.',
    web_framework_documentation_downloaded: 'O destinatario descargou a documentación.',
    web_framework_electronic_notificacion_rejected: 'O destinatario rexeitou a notificación electrónica.',
    web_framework_gdpr_formulary_committed: 'O destinatario encheu o formulario de consentimento.',
    web_framework_sepa_formulary_committed: 'O destinatario encheu o formulario SEPA.',
    web_framework_requested_otp_code: 'Solicitouse unha clave dun só uso (OTP).',
    web_framework_entered_wrong_otp_code: 'A clave dun só uso (OTP) introducida polo destinatario é incorrecta.',
    web_framework_entered_timedout_otp_code: 'A clave dun só uso caducou.',
    web_framework_entered_correct_otp_code: 'A clave dun só uso (OTP) introducida polo destinatario é correcta.',
    web_framework_electronic_notificacion_accepted: 'O destinatario aceptou a notificación electrónica.',
    web_framework_signature_rejected: 'O destinatario rexeitou a solicitude de sinatura electrónica.',
    web_framework_request_otp_code_too_early: 'O destinatario solicitou a clave dun só uso (OTP) antes do estipulado para poder rexenerala',
    web_framework_capture_data_commited_no_response: 'Intentouse realizar un envío do formulario sen datos nas respostas.',
    web_framework_capture_data_commited: 'Realizouse correctamente o envío de datos',
    web_framework_document_previewed: 'O destinatario consultou a vista previa da documentación.',
    web_framework_document_simply_signed: 'Asinouse electronicamente a documentación.',
    document_upload_alastria_uploaded: 'O hash do certificado de finalización subiuse ao nodo de CertySign na rede Blockchain de Alastria.',
    publish_downloadable_file_generated: 'Xerouse a documentación para publicala na interface web.',
    publish_downloadable_file_open: 'Abriuse a documentación para publicala na interface web.',
    document_sign_signed: 'Asinouse o certificado',
    rpc_callback_published: 'Publicouse o retorno da chamada RPC',
    loop_add_added: 'Engadiuse a transacción ao bucle de novos intentos.',
    loop_add_tick: 'Realizouse un intento adicional',
    loop_add_timeout: 'Acadouse o límite de novos intentos',
    loop_cancel_canceled: 'Cancelouse o bucle',
    identification_create_identification_generated: 'Creouse o proceso mediante o que se identificará o usuario na transacción.',
    identification_create_identification_ok: 'Identificouse de xeito correcto',
    test_load_processed: 'Procesouse a proba de carga',
    instance_delete_deleted: 'Borrouse a instancia correspondente á transacción.',
    unzip_file_extracted: 'Descomprimiuse o ficheiro.',
    communicalia_consent_accepted: 'O destinatario da comunicación aceptou o consentimento.',
    communicalia_consent_declined: 'O destinatario da comunicación rexeitou o consentimento.',
    communicalia_consent_no_data: 'O destinatario da comunicación non respondeu.',
    robinson_list_accepted: 'O destinatario non está na lista Robinson',
    robinson_list_declined: 'O destinatario está dado de alta na lista Robinson',
    robinson_list_no_data: 'O destinatario está dado de alta na lista Robinson',
    publish_embeddable_file_generated: 'Xerouse o ficheiro inserido para a interface web.',
    document_upload_sftp_uploaded: 'Realizouse a carga da documentación a través de SFTP.',
    file_delete_deleted: 'Borrouse o ficheiro.',
    disable_framework_web_framework_web_disabled: 'Desactivouse a interface web asociada á transacción.',
    generate_onespan_transaction_transaction_create: 'Creouse a transacción de sinatura electrónica con OneSpan',
    generate_onespan_transaction_transaction_create_error: 'Ocorreu un erro ao crear a transacción de sinatura electrónica de OneSpan.',
    generate_onespan_transaction_transaction_complete: 'Finalizou a transacción de sinatura electrónica de OneSpan.',
    generate_onespan_transaction_transaction_expire: 'Caducou a transacción de sinatura electrónica de OneSpan.',
    generate_onespan_transaction_transaction_decline: 'Rexeitouse a transacción de sinatura electrónica de OneSpan.',
    generate_onespan_transaction_email_bounce: 'Rebotou a mensaxe de correo electrónico correspondente á transacción de sinatura electrónica de OneSpan.',
    generate_onespan_transaction_transaction_opt_out: 'O destinatario optou por non asinar a transacción.',
    generate_onespan_transaction_transaction_delete: 'Eliminouse a transacción de sinatura electrónica de OneSpan.',
    generate_onespan_transaction_transation_desactivate: 'Desactivouse a transacción de sinatura electrónica de OneSpan.',
    generate_onespan_transaction_kba_failure: 'A transacción bloqueouse por mor da seguridade do acceso ao proceso.',
    generate_onespan_transaction_transaction_sent: 'Enviouse a transacción de sinatura electrónica de OneSpan.',
    generate_onespan_transaction_transaction_sent_error: 'Fallou o envío da transacción de sinatura electrónica de OneSpan.',
    generate_onespan_transaction_package_trash: 'A transacción moveuse á papeleira de OneSpan',
    get_onespan_files_files_onespan_generated: 'Xeráronse os ficheiros necesarios para OneSpan',
    communication_send_postal_generated: 'Xerouse o envío da comunicación postal.',
    communication_send_postal_sent_to_factory: 'O envío cargouse na plataforma de comunicación postal.',
    communication_send_postal_printed: 'Imprimiuse a documentación asociada ao envío postal.',
    communication_send_postal_pre_admission_requested: 'O envío da comunicación estase a rexistrar telematicamente con antelación na web do operador postal.',
    communication_send_postal_pre_admitted: 'El envío da comunicación rexistrouno telematicamente o operador postal antes de depositalo.',
    communication_send_postal_sent_to_logistic_operator: 'O envío da comunicación estase a depositar no operador postal.',
    communication_send_postal_on_the_way_to_logistic_operator: 'O envío vai de camiño á oficina postal máis próxima ao destinatario da comunicación.',
    communication_send_postal_received_by_logistic_operator: 'O envío está na oficina postal máis próxima ao destinatario da comunicación.',
    communication_send_postal_not_received_by_logistic_operator: 'O envío da comunicación non chegou á oficina postal.',
    communication_send_postal_on_delivery: 'O envío da comunicación postal está en camiño.',
    communication_send_postal_delivered_to_recipient: 'O envío da comunicación postal entregouse ao destinatario.',
    communication_send_postal_returned_to_postal_office: 'O envío está na oficina postal e o destinatario da comunicación ten un aviso na caixa de correo',
    communication_send_postal_delivered_into_postal_office: 'O envío da comunicación entregouse ao destinatario na oficina postal.',
    communication_send_postal_generic_delivered: 'O envío da comunicación postal entregouse ao destinatario.',
    communication_send_postal_returned: 'Non foi posible entregar o envío e a comunicación postal devolveuse á orixe.',
    communication_send_postal_canceled: 'Cancelouse o envío da comunicación postal.',
    communication_send_postal_lost: 'Extraviouse o envío da comunicación postal.',
    communication_send_postal_expired: 'O envío da comunicación postal devolveuse á orixe porque o destinatario non o recolleu no prazo de 15 días hábiles dende que se deixou o aviso na caixa de correo.',
    communication_send_postal_rejected: 'O destinatario rexeitou o envío da comunicación postal.',
    communication_send_postal_stolen: 'Subtraeuse o envío da comunicación postal.',
    communication_send_postal_outside_sicer: 'O envío da comunicación non está rexistrado no operador postal.',
    communication_send_postal_bad_postal_address: 'O enderezo postal do envío non é correcto.',
    communication_send_postal_passed_away: 'O destinatario do envío da comunicación postal faleceu.',
    communication_send_postal_other: 'Outros.',
    communication_send_postal_no_information: 'No operador postal non consta información do envío da comunicación.',
    communication_send_postal_received_pee: 'Recibiuse a proba electrónica de entrega (PEE) del envío.',
    communication_send_postal_certificate_returned: 'Xerouse o certificado de devolución con proba da imposibilidade de entrega por parte do operador postal.',
    communication_send_postal_unknown: 'O destinatario do envío da comunicación non se coñece no enderezo postal indicado.',
    zip_files_path_zipped: 'Comprimíronse un ou varios documentos',
    intromission_api_retries_muted: 'Desactiváronse os novos intentos de transacción',
    intromission_api_retries_unmuted: 'Activáronse os novos intentos de transacción',
    intromission_api_instance_canceled: 'Cancelouse a transacción',
    antivirus_filtered: 'O antivirus filtrou a transacción debido a que se subiron recursos potencialmente perigosos.',
    //API messages
    user_operation_error_creating_user: 'Ocorreu un erro ao crear o usuario',
    user_operation_user_already_exists: 'O usuario xa existe',
    user_operation_user_created: 'Usuario creado correctamente',
    user_operation_user_updated: 'Usuario actualizado correctamente',
    user_operation_no_exists: 'O usuario non existe',
    user_operation_password_changed: 'Actualizouse o contrasinal',
    user_operation_pass_incorrect: 'A combinación de usuario e contrasinal é incorrecta',
    user_operation_password_expired: 'O contrasinal caducou',
    user_operation_logout: 'Sesión pechada correctamente',
    user_operation_blocked_user: 'O usuario está bloqueado',
    user_operation_wrong_subdomain: 'O nome de usuario ou o contrasinal non son correctos',
    user_operation_max_pass_tries: 'Usuario bloqueado; superouse o número de intentos',
    user_operation_deleted: 'Usuario borrado correctamente',
    user_operation_error_deleted: 'Erro ao borrar o usuario',
    user_has_been_disabled: 'Usuario desactivado',
    user_operation_error_user_not_found: 'Se o correo electrónico indicado existe, en breve recibirá unha mensaxe coas indicacións para cambiar o contrasinal',
    user_operation_remember_password_email: 'Se o correo electrónico indicado existe, en breve recibirá unha mensaxe coas indicacións para cambiar o contrasinal',
    email_sent_to_support: 'Solicitude enviada correctamente',
    user_operation_error_change_password_min_days: 'Non é posible cambiar o contrasinal porque non pasou o mínimo de días requirido',
    user_operation_error_passwords_mismatch: 'Os contrasinais non coinciden',
    user_operation_error_password_has_been_used_before: 'O contrasinal xa se usou anteriormente; elixa outro',
    user_or_group_no_exists: 'O usuario ou grupo non existen',
    user_error_already_active: 'O usuario xa está activo',
    activation_email_resend: 'Reenviouse o correo electrónico de activación',
    group_operation_error_creating_group: 'Ocorreu un erro ao crear o grupo',
    group_operation_group_already_exists: 'O grupo xa existe',
    group_operation_group_created: 'Grupo creado correctamente',
    group_operation_group_updated: 'Grupo :group_name: actualizado correctamente',
    //Terms and conditions
    termsConditionsDPOTitle: 'Delegado de Protección de datos',
    termsConditionsTitular: 'Titular',
    termsConditionsNIF: 'NIF',
    termsConditionsDomicilioSocial: 'Domicilio social',
    termsConditionsDatosRegistrales: 'Datos rexistrais',
    termsConditionsContacto: 'Contacto',
    termsConditionsDPOEmailTitle: 'Correo electrónico de Customer Comms',
    termsConditionsDPOEmail: 'Correo electrónico do delegado de Protección de datos',
    termsConditions1: 'CUSTOMER COMMUNICATIONS TECKNALIA, S.L. (en diante, CCOMMS), na súa condición de titular e responsable da presente APP, en cumprimento do establecido no artigo 10 da Lei 34/2002, de 11 de xullo, de Servizos da Sociedade da Información e de Comercio Electrónico, pon a disposición dos usuarios a seguinte información xeral sobre esta APP:',
    termsConditionsObjetoTitulo: 'OBXECTO',
    termsConditionsObjetoParrafo1: 'As presentes Condicións Xerais de Uso (en diante, as CXU) regulan o uso da aplicación CertySign (en diante, a APP) que CCOMMS pon a disposición dos usuarios que accedan co gallo de poder satisfacer a solicitude realizada.',
    termsConditionsObjetoParrafo2: 'Polo tanto, as presentes CXU aplicaranse tanto á actividade promocional e de subministración de información que se realiza a través da APP como ao fornecemento dos servizos ofrecidos, de tal xeito que rexerán en todo momento tanto para a simple navegación pola APP como para a contratación de servizos no marco da APP, se ben esta última actividade adicionalmente someterase ás condicións xerais de contratación aplicables e ás condicións particulares que poidan existir.',
    termsConditionsUsuariosTitulo: 'USUARIOS',
    termsConditionsUsuariosParrafo1: 'O acceso á APP ou o uso dela outorgan a quen os realice a condición de usuario; o usuario acepta dende ese mesmo intre, plenamente e sen ningunha reserva, as presentes CXU, así como as condicións particulares que poidan complementar, modificar ou substituír as CXU en relación con determinados servizos e contidos da APP, obrigándose a respectar as restricións de uso da páxina establecidas por CCOMMS en cada momento.',
    termsConditionsAccesoAPPTitulo: 'ACCESO Á APP',
    termsConditionsAccesoAPPParrafo1: 'O acceso á APP e o uso dela teñen carácter restrinxido e requirirán o previo rexistro do usuario.',
    termsConditionsUsoAPPTitulo: 'USO DA APP',
    termsConditionsUsoAPPParrafo1: 'O usuario comprométese a utilizar a APP e os seus servizos e contidos sen contravir a lexislación vixente, a boa fe, os usos xeralmente aceptados e a orde pública. Así mesmo, queda prohibido o uso da APP con fins ilícitos ou lesivos para CCOMMS ou calquera terceiro, ou que poidan de calquera xeito causar prexuízo ou impedir o correcto funcionamento da APP.',
    termsConditionsResposabilidadTitulo: 'RESPONSABILIDADE DO USUARIO POR DANOS E PREXUÍZOS',
    termsConditionsResposabilidadParrafo1: 'O uso da APP farase baixo a única e exclusiva responsabilidade do usuario. Esta responsabilidade estenderase ao uso por parte do usuario ou dun terceiro de calquera contrasinal ou similar asignados para acceder á APP ou os seus servizos.',
    termsConditionsResposabilidadParrafo2: 'Sen prexuízo do anterior, CCOMMS resérvase o dereito a denegar ou inhabilitar, en calquera momento e sen necesidade de aviso previo, o acceso á APP ou o acceso restrinxido para aqueles usuarios que incumpran estas CXU ou as normas establecidas por CCOMMS ou os seus colaboradores, ou que perturben o bo funcionamento, a imaxe, a credibilidade ou o prestixio de CCOMMS ou os seus colaboradores.',
    termsConditionsResposabilidadParrafo3: 'Neste sentido, CCOMMS resérvase a facultade de cancelar ou restrinxir o acceso á APP para determinados usuarios en caso de observar calquera conduta que, ao seu xuízo, resulte contraria a estas condicións de uso, la lei ou as normas establecidas por CCOMMS ou os seus colaboradores, ou que perturbe o bo funcionamento, a imaxe, a credibilidade ou o prestixio de CCOMMS ou os seus colaboradores. Así mesmo, CCOMMS poderá esixir responsabilidade aos usuarios polos danos e prexuízos derivados dun mal uso da APP.',
    termsConditionsResposabilidadParrafo4: 'CCOMMS non garante a licitude, fiabilidade e utilidade, así como a veracidade ou exactitude, dos contidos que os usuarios transmitan durante a utilización da APP.',
    termsConditionsResposabilidadParrafo5: 'CCOMMS non controla o uso que os usuarios fagan da APP nin garante que este uso cumpra as presentes Condicións Xerais.',
    termsConditionsResposabilidadParrafo6: 'CCOMMS non controla nin garante que nos servizos fornecidos por terceiros a través da APP non existan virus informáticos que poidan producir alteracións no seu sistema informático (software e hardware) ou nos documentos e ficheiros electrónicos almacenados no seu sistema informático.',
    termsConditionsPropiedadIntelectualTitulo: 'PROPIEDADE INTELECTUAL',
    termsConditionsPropiedadIntelectualParrafo1: 'Calquera uso ou modificación do material ou dos contidos para calquera fin distinto do autorizado nas Condicións Xerais consideraranse unha infracción das leis internacionais de "copyright", que protexen os dereitos de autor.',
    termsConditionsPropiedadIntelectualParrafo2: 'Respecto aos contidos, prohíbese:',
    termsConditionsPropiedadIntelectualListaELemento1: 'A súa reprodución, copia, distribución, modificación ou manipulación, de calquera xeito e con calquera finalidade, a menos que se dispoña da autorización previa por escrito dos lexítimos titulares ou que estea permitido por lei.',
    termsConditionsPropiedadIntelectualListaELemento2: 'Calquera vulneración dos dereitos de CCOMMS ou dos lexítimos titulares.',
    termsConditionsPropiedadIntelectualListaELemento3: 'A súa utilización para todo tipo de fins comerciais ou publicitarios distintos dos estritamente permitidos.',
    termsConditionsPropiedadIntelectualParrafo3: 'CCOMMS garante que os contidos, incluídos os de propiedade intelectual, non son de carácter ilícito nin infrinxen a normativa vixente. Polo tanto, os contidos da APP non terán carácter xenófobo, pornográfico, discriminatorio nin racista, nin fomentarán de calquera outro xeito a violencia.',
    termsConditionsPropiedadIntelectualParrafo4: 'Ademais, CCOMMS adoptará as medidas legais que considere convenientes para impedir calquera tipo de conduta contraria á lei ou inmoral.',
    termsConditionsModificacionUnilateralTitulo: 'MODIFICACIÓN UNILATERAL',
    termsConditionsModificacionUnilateralParrafo1: 'CCOMMS poderá modificar unilateralmente e sen previo aviso, sempre que o considere oportuno, a estrutura e o deseño da APP, así como cambiar ou eliminar os servizos, os contidos e as condicións de acceso ou uso da APP.',
    termsConditionsExclusionGarantiasTitulo: 'EXCLUSIÓN DE GARANTÍAS E RESPONSABILIDADE',
    termsConditionsExclusionGarantiasParrafo1: 'CCOMMS non outorga ningunha garantía nin se fai responsable, en ningún caso, respecto aos danos e prexuízos de calquera tipo que puideran derivarse de:',
    termsConditionsExclusionGarantiasListaELemento1: 'A non observación da lei, a moral e os bos costumes, así como a alteración da orde pública ou o uso dos produtos ou servizos ofertados de xeito pouco dilixente ou incorrecto, ou con fins ilícitos ou contrarios ao establecido nas presentes CXU ou nas condicións que sexan aplicables en cada caso.',
    termsConditionsExclusionGarantiasListaELemento2: 'A adquisición ou contratación de servizos, bens ou produtos de terceiros a través dun acceso na APP de CCOMMS, especialmente naqueles casos en que o proceso de compra ou contratación se realice directamente na APP do terceiro, aínda que aparezan distintivos ou un «frame» cos elementos gráficos da APP (branding).',
    termsConditionsExclusionGarantiasListaELemento3: 'A falta de dispoñibilidade, mantemento e funcionamento efectivo da APP ou os seus servizos ou contidos.',
    termsConditionsExclusionGarantiasListaELemento4: 'A falta de utilidade, adecuación ou validez da APP ou dos seus servizos ou contidos para necesidades, actividades ou resultados concretos ou as expectativas dos usuarios.',
    termsConditionsExclusionGarantiasListaELemento5: 'A existencia de virus ou programas maliciosos ou lesivos nos contidos.',
    termsConditionsExclusionGarantiasListaELemento6: 'A recepción, obtención, almacenamento, difusión ou transmisión por parte dos usuarios dos contidos.',
    termsConditionsExclusionGarantiasListaELemento7: 'A ilicitude ou falta de veracidade, exactitude, fiabilidade, pertinencia, actualidade e exhaustividade dos contidos e informacións transmitidos ou postos a disposición dos usuarios, incluíndo as informacións e servizos fornecidos por terceiros ou polos usuarios a través da APP.',
    termsConditionsExclusionGarantiasListaELemento8: 'O incumprimento por parte de terceiros das súas obrigas ou compromisos en relación cos servizos fornecidos aos usuarios a través da APP.',
    termsConditionsExclusionGarantiasListaELemento9: 'Os contidos, ficheiros, informacións, publicidade, opinións, conceptos e imaxes que non dependan da APP nin estean xestionados por CCOMMS. Así mesmo, o mal uso que se faga dos contidos da APP, cuxa exclusiva responsabilidade recae na persoa que acceda a eles ou os utilice.',
    termsConditionsExclusionGarantiasListaELemento10: 'O uso indebido das claves de usuario para acceder aos contidos do servizo da APP que as requiran e as consecuencias de calquera tipo derivadas do mal uso ou da perda ou esquecemento por parte dos usuarios, e do uso indebido por parte de terceiros non autorizados.',
    termsConditionsExclusionGarantiasListaELemento11: 'A incapacidade de calquera usuario ou a suplantación da personalidade dun terceiro por parte do usuario.',
    termsConditionsExclusionGarantiasListaELemento12: 'Os contidos fornecidos polos usuarios.',
    termsConditionsProteccionDatosTitulo: 'PROTECCIÓN DE DATOS',
    termsConditionsProteccionDatosParrafo1: 'Pode consultar a información detallada sobre protección de datos na nosa páxina de',
    termsConditionsLeyJurisdiccionTitulo: 'LEI APLICABLE E XURISDICIÓN',
    termsConditionsLeyJurisdiccionParrafo1: 'Todas as condicións, xerais e particulares, aplicables á presente APP réxense pola normativa española. Todos os conflitos, controversias ou situacións derivadas das condicións xerais e particulares resolveranse nos xulgados e tribunais da Vila de Madrid, renunciando expresamente as partes ao seu foro.',
    termsConditionsProteccionDatosTitle: 'Consultar información de protección de datos',
    termsConditionsProteccionDatos: 'Protección de datos',
    //Privacy Policies
    privacyPoliciesConditionsTitle: 'Protección de datos de MailTeck e Customer Communications Tecknalia',
    privacyPoliciesConditionsParrafo1: 'De conformidade co establecido tanto no Regulamento (UE) 2016/679 do Parlamento Europeo e do Consello, de 27 de abril de 2016, relativo á protección das persoas físicas no que respecta ao tratamento dos datos persoais e a libre circulación destes (RXPD), como na Lei Orgánica 3/2018, de 5 de decembro, de Protección de Datos Persoais e garantía dos dereitos dixitais (LOPD-GDD) e demais normativas sobre protección de datos vixentes, infórmase os usuarios da APP, que manifestaron o seu consentimento para o tratamento dos datos persoais voluntaria, clara e inequivocamente ao marcar a caixa da política de protección de datos, que os seus datos van tratalos, en función da empresa na que se deran de alta, a empresa MAILTECK, S.A. ou ben a empresa CUSTOMER COMMUNICATIONS TECKNALIA, S.L., as cales se coñecerán conxuntamente como GRUPO MAILCOMMS.',
    privacyPoliciesConditionsResponsable1Title: 'Responsable 1',
    privacyPoliciesConditionsResponsable2Title: 'Responsable 2',
    privacyPoliciesConditionsFinalidadTitle: 'Finalidade',
    privacyPoliciesConditionsLegitimacionTitle: 'Lexitimación',
    privacyPoliciesConditionsDestinatariosTitle: 'Destinatarios',
    privacyPoliciesConditionsDerechosTitle: 'Dereitos',
    privacyPoliciesConditionsInformacionAdicionalTitle: 'Información adicional',
    privacyPoliciesConditionsPoliticaInformacion1Title: 'Política de información 1',
    privacyPoliciesConditionsPoliticaInformacion2Title: 'Política de información 2',
    privacyPoliciesConditionsIdentidadTitle: 'Identidade',
    privacyPoliciesConditionsNIFTitle: 'NIF',
    privacyPoliciesConditionsDireccionTitle: 'Enderezo',
    privacyPoliciesConditionsTelefonoTitle: 'Teléfono',
    privacyPoliciesConditionsCorreoTitle: 'Correo',
    privacyPoliciesConditionsDPDTitle: 'Contacto co DPD',
    privacyPoliciesConditionsDPDCustomerCommsTitle: 'Delegado de Protección de datos de Customer Comms',
    privacyPoliciesConditionsDPDMailteckTitle: 'Delegado de Protección de datos de MailTeck',
    privacyPoliciesConditionsFinalidadIntro: 'Xestionar e tramitar os servizos contratados',
    privacyPoliciesConditionsFinalidadParrafo1: 'GRUPO MAILCOMMS vai tratar os datos fornecidos, como parte da execución dun contrato subscrito por vostede ou a súa empresa, co gallo de xestionar e tramitar os servizos pactados nel, así como ofrecer soporte da aplicación CertySign.',
    privacyPoliciesConditionsFinalidadParrafo2: 'GRUPO MAILCOMMS só tratará os datos fornecidos para os fins descritos anteriormente; os datos non se tratarán posteriormente de xeito incompatible cos devanditos fins. Os datos persoais fornecidos conservaranse mentres se manteña a relación contractual.',
    privacyPoliciesConditionsLegitimacionIntro: 'Execución do contrato',
    privacyPoliciesConditionsLegitimacionParrafo1: 'Os datos fornecéronos vostede ou a súa empresa a través das canles establecidas para poder ter acceso á aplicación CertySign, segundo o contrato de fornecemento de servizo subscrito.',
    privacyPoliciesConditionsDestinatariosIntro: 'Non se ceden a terceiros',
    privacyPoliciesConditionsDestinatariosParrafo1: 'Por esixencia legal, cando unha norma así o esixa.',
    privacyPoliciesConditionsDerechosIntro: 'Pode exercer os seus dereitos do xeito e nos prazos legalmente previstos.',
    privacyPoliciesConditionsDerechosElem1ListaTitulo: 'Confirmación xeral:',
    privacyPoliciesConditionsDerechosElem1ListaDescripcion: 'Calquera persoa ten dereito a que lle confirmen se o GRUPO MAILCOMMS está a tratar datos persoais que lle concirnan.',
    privacyPoliciesConditionsDerechosElem2ListaTitulo: 'Dereitos ARCO:',
    privacyPoliciesConditionsDerechosElem2ListaDescripcion: 'As persoas interesadas teñen dereito a acceder aos seus datos persoais e a solicitar que se rectifiquen os que sexan inexactos ou a que se eliminen todos os datos cando, por exemplo, xa non sexan necesarios para os fins cos que se recolleron.',
    privacyPoliciesConditionsDerechosElem3ListaTitulo: 'Limitación do tratamento:',
    privacyPoliciesConditionsDerechosElem3ListaDescripcion: 'En determinadas circunstancias, os interesados poderán solicitar que se limite o tratamento dos datos, en cuxo caso unicamente se conservarán para presentar ou defender reclamacións.',
    privacyPoliciesConditionsDerechosElem4ListaTitulo: 'Oposición ao tratamento:',
    privacyPoliciesConditionsDerechosElem4ListaDescripcion: 'En determinadas circunstancias, por motivos relacionados coa súa situación particular, os interesados poderán opoñerse ao tratamento dos datos. Neste caso, GRUPO MAILCOMMS deixará de tratalos, salvo por motivos lexítimos imperiosos ou para presentar ou defender reclamacións.',
    privacyPoliciesConditionsDerechosElem5ListaTitulo: 'Portabilidade dos datos:',
    privacyPoliciesConditionsDerechosElem5ListaDescripcion: 'En determinadas circunstancias, por motivos relacionados coa súa situación particular, os interesados poderán solicitar recibir os datos persoais fornecidos no formulario de contacto, un formulario estruturado, de uso común e lexible por máquina, así como transferir os devanditos datos a outro responsable do tratamento sen impedimentos.',
    privacyPoliciesConditionsDerechosElem6ListaTitulo: 'Reclamación ante organismo público:',
    privacyPoliciesConditionsDerechosElem6ListaDescripcion: 'Se considera que se vulnerou o seu dereito á protección dos datos persoais, poderá interpoñer unha reclamación ante a Axencia Española de Protección de Datos',
    privacyPoliciesConditionsDerechosAEPDTitle: 'AEPD',
    privacyPoliciesConditionsDerechosElem7ListaTitulo: 'Canle para exercer os dereitos:',
    privacyPoliciesConditionsDerechosElem7ListaDescripcion: 'Os interesados poderán exercer os dereitos que se lles recoñece na normativa fronte a, e en contra de, cada un dos responsables, nos enderezos de DPD do apartado "Responsable".',
    privacyPoliciesConditionsInformacionAdicionalIntro: 'Máis información sobre a protección de datos.',
    privacyPoliciesConditionsInformacionAdicionalLista1: 'Os datos persoais obtivéronse a través das canles que estableceron as empresas do GRUPO MAILCOMMS para poder xestionar os servizos contratados por vostede ou a súa empresa.',
    privacyPoliciesConditionsInformacionAdicionalLista2: 'Os datos que van tratar serán os fornecidos para o desenvolvemento dos diferentes servizos contratados.',
    privacyPoliciesConditionsInformacionAdicionalLista3: 'GRUPO MAILCOMMS non trata datos protexidos especialmente.',
    privacyPoliciesConditionsPoliticaInformacionIntro: 'MailTecK, S.A. Política de seguridade e privacidade da información baseada na norma ISO 27001 -> Ler máis.',
    privacyPoliciesConditionsPoliticaInformacio2nIntro: 'Customer Communication Tecknalia, S.L. Política de seguridade e privacidade da información baseada na norma ISO 27001 -> Ler máis.',
    privacyPoliciesConditionsPoliticaInformacionTituloH: 'ANEXO I: POLÍTICA DO SISTEMA DE XESTIÓN DA SEGURIDADE E PRIVACIDADE DA INFORMACIÓN.',
    privacyPoliciesConditionsPoliticaInformacionP1: 'Esta Política de seguridade e privacidade da información demostra o compromiso da dirección, xa que dota a organización de recursos, responsabilidades e procedementos que lle permitan garantir o cumprimento da normativa vixente, así como a integridade, confidencialidade e dispoñibilidade da información e os sistemas, sendo crucial para a seguridade, privacidade e continuidade da nosa organización, así como dos nosos clientes.',
    privacyPoliciesConditionsPoliticaInformacionP2: 'Grupo MailTeck, consciente de que a seguridade e a privacidade da información dos clientes son de vital importancia, estableceu un sistema de xestión da seguridade e privacidade da información de acordo cos requisitos das normas ISO/IEC 27001:2013 e ISO/IEC 27701:2019, co gallo de garantir os niveis de seguridade requiridos, a continuidade dos servizos fornecidos e o cumprimento legal respecto ao tratamento dos datos de carácter persoal, minimizando así os riscos derivados desta xestión.',
    privacyPoliciesConditionsPoliticaInformacionCCTP2: 'Custommer Comms, consciente de que a seguridade e a privacidade da información dos clientes son de vital importancia, estableceu un sistema de xestión da seguridade e privacidade da información de acordo cos requisitos das normas ISO/IEC 27001:2013 e ISO/IEC 27701:2019, co gallo de garantir os niveis de seguridade requiridos, a continuidade dos servizos fornecidos e o cumprimento legal respecto ao tratamento dos datos de carácter persoal, minimizando así os riscos derivados desta xestión.',
    privacyPoliciesConditionsPoliticaInformacionP3: 'O obxectivo da Política de seguridade e privacidade é establecer un marco de actuación necesario para protexer os recursos da información fronte a ameazas, internas ou externas, deliberadas ou accidentais, co gallo de asegurar o cumprimento en materia de confidencialidade, integridade e dispoñibilidade da información.',
    privacyPoliciesConditionsPoliticaInformacionP4: 'A eficacia e aplicación do Sistema de xestión da seguridade e privacidade da información dependen directamente do Comité de seguridade e privacidade da información, o cal é responsable da aprobación, difusión e cumprimento da presente Política de seguridade e privacidade. No seu nome e representación, nomeouse un responsable do Sistema de xestión da seguridade e privacidade da información, que posúe autoridade abondo para desempeñar un papel activo no devandito sistema, supervisando a súa implantación, desenvolvemento e mantemento.',
    privacyPoliciesConditionsPoliticaInformacionP5: 'Adicionalmente, para cumprir a normativa vixente de protección de datos, nomeouse un delegado de Protección de datos (DPD) co gallo de garantir a seguridade e privacidade dos datos de carácter persoal, especialmente tendo en conta a actividade da empresa.',
    privacyPoliciesConditionsPoliticaInformacionP6: 'O Comité de seguridade e privacidade da información procederá a desenvolver e aprobar a metodoloxía de análise de riscos utilizada no Sistema de xestión da seguridade e privacidade da información.',
    privacyPoliciesConditionsPoliticaInformacionP7: 'Toda persoa cuxa actividade poida verse afectada, directa ou indirectamente, polos requisitos do Sistema de xestión da seguridade e privacidade da información está obrigada a cumprir estritamente a Política de seguridade e privacidade.',
    privacyPoliciesConditionsPoliticaInformacionP8: 'En Grupo MailTeck implementaranse todas as medidas necesarias para cumprir a normativa aplicable en materia de seguridade e privacidade xerais e de seguridade informática, relativa á política informática, a seguridade de edificios e instalacións e o comportamento de empregados e terceiros asociados co Grupo MailTeck no referente ao uso de sistemas informáticos e tratamento dos datos persoais. As medidas necesarias para garantir a seguridade e privacidade da información mediante a aplicación de normas, procedementos e controis deberán permitir asegurar a confidencialidade, integridade e dispoñibilidade da información, que son esenciais para:',
    privacyPoliciesConditionsPoliticaInformacionCCTP8: 'En Custommer Comms implementaranse todas as medidas necesarias para cumprir a normativa aplicable en materia de seguridade e privacidade xerais e de seguridade informática, relativa á política informática, a seguridade de edificios e instalacións e o comportamento de empregados e terceiros asociados con Custommer Comms no referente ao uso de sistemas informáticos e tratamento dos datos persoais. As medidas necesarias para garantir a seguridade e privacidade da información mediante a aplicación de normas, procedementos e controis deberán permitir asegurar a confidencialidade, integridade e dispoñibilidade da información, que son esenciais para:',
    privacyPoliciesConditionsPoliticaInformacionLista1: 'Cumprir coa lexislación, regulamentación e normativa vixentes en materia de privacidade e dos sistemas de información, así como con todos os requisitos contractuais en materia de protección de datos e os que considere oportuno aplicar co obxectivo de lograr unha mellora continua da organización.',
    privacyPoliciesConditionsPoliticaInformacionLista2: 'Asegurar a confidencialidade dos datos xestionados por Grupo MailTeck.',
    privacyPoliciesConditionsPoliticaInformacionCCTLista2: 'Asegurar a confidencialidade dos datos xestionados por Custommer Comms.',
    privacyPoliciesConditionsPoliticaInformacionLista3: 'Asegurar a dispoñibilidade dos sistemas de información, tanto nos servizos ofrecidos aos clientes como na xestión interna.',
    privacyPoliciesConditionsPoliticaInformacionLista4: 'Asegurar a capacidade de resposta ante situacións de emerxencia, restablecendo o funcionamento dos servizos críticos no menor tempo posible.',
    privacyPoliciesConditionsPoliticaInformacionLista5: 'Evitar alteracións indebidas na información.',
    privacyPoliciesConditionsPoliticaInformacionLista6: 'Promover a concienciación e formación en seguridade e privacidade da información.',
    privacyPoliciesConditionsPoliticaInformacionLista7: 'Impulsar activamente a mellora continua do Sistema de xestión da seguridade e privacidade da información.',
    cookiesPoliciesIntroText: 'Este sitio web utiliza rastros para mellorar a experiencia de navegación por el. Pode cambiar estes axustes en calquera momento. Os rastros clasificados como necesarios almacénanse no navegador e son esenciais para as funcións básicas do sitio web. Os rastros de terceiros almacénanse no navegador só se dá o seu consentimento e axúdannos a analizar e comprender como se utiliza o sitio web. Pode optar por non recibir estes rastros; non obstante, a exclusión voluntaria dalgún destes rastros pode facer que algunhas funcións deixen de estar dispoñibles. Para obter información sobre a eliminación dos rastros, consulte a axuda do navegador.',
    cookiesPoliciesNecessaryCookies: 'Rastros necesarios',
    cookiesPoliciesNecessaryCookiesText: 'Este tipo de rastros son absolutamente esenciais para que o sitio web funcione correctamente. Esta categoría só inclúe rastros que garanten funcións básicas e características de seguridade do sitio web e non recompilan nin almacenan ningunha información persoal sobre os usuarios do sitio web.',
    cookiesPoliciesUnnecessaryCookies: 'Rastros non necesarios',
    cookiesPoliciesUnnecessaryCookiesText: 'Denomínanse rastros non necesarios aqueles que poden non ser especialmente necesarios para que o sitio web funcione e se utilizan especificamente para recompilar datos persoais do usuario a través de análises, anuncios e outros contidos integrados. É obrigatorio obter o consentimento do usuario antes de executar estes rastros no sitio web.',
    cookiesPoliciesGoogleAnalyticsCookies: 'Rastros de Google Analytics',
    cookiesPoliciesGoogleAnalyticsCookiesText: 'Para obter máis información sobre este tipo de rastros, pode consultar o documento «Uso dos rastros de Google Analytics en sitios web»',
    cookiesPoliciesGoogleAnalyticsCheckboxText: 'Manter rastros activos',
    cookiesNotificationBarText: 'Utilizamos rastros, tanto propios como de terceiros, para computar as visitas, garantir as funcións do sitio web e analizar a navegación por este. A continuación, pode aceptar os rastros ou configurar/rexeitar o seu uso. Pode obter máis información sobre o titular da web, que é responsable do tratamento dos rastros e dos seus datos de contacto, na nosa',
    cookiesNotificationBarConfig: 'Configurar',
    cookiesPoliciesModalIntro: 'Introdución',
    cookiesPoliciesModalIntroP1: 'Este sitio web utiliza rastros ou tecnoloxías similares que almacenan e recuperan información durante a navegación para mellorar e optimizar a experiencia do usuario.',
    cookiesPoliciesModalIntroP2: 'En xeral, estas tecnoloxías poden servir para fins moi diversos, como, por exemplo, recoñecer os usuarios, obter información sobre hábitos de navegación ou personalizar o xeito en que se amosa o contido.',
    cookiesPoliciesModalIntroP3: 'De conformidade co Real Decreto 13/2012 e a súa afectación no artigo 22.2 da Lei de Servizos da Sociedade da Información e Comercio Electrónico, mediante a presente política comunícaselle que rastros, propios ou de terceiros, exentos ou non do deber de notificación, recompila CUSTOMER COMMUNICATIONS TECKNALIA, S.L. a través dos distintos medios ou canles de comunicación, así como por que medios se poden rexeitar, permitir ou bloquear os devanditos rastros.',
    cookiesPoliciesModalIntroP4: 'A continuación, fornécese información detallada sobre que son os rastros, que tipos se utilizan neste sitio web, como se poden desactivar nun navegador e como bloquear especificamente a instalación de rastros de terceiros.',
    cookiesPoliciesModalResponsibility: 'Quen é o responsable do uso dos rastros no presente sitio web?',
    cookiesPoliciesModalResponsibilityP1: 'O responsable do uso dos rastros é CUSTOMER COMMUNICATIONS TECKNALIA, S.L. (en diante, CCOMMS), con NIF número B-86414000 e con domicilio social en Leganés (Madrid), Avenida de la Recomba, 14, debidamente inscrita no Rexistro Mercantil de Madrid ao Tomo 29 801, Folio 98, Sección 8.ª, Folla número M-536210.',
    cookiesPoliciesModalResponsibilityP2: 'Correo electrónico do delegado de Protección de datos.',
    cookiesPoliciesModalWhatIsCookie: 'Que é un rastro?',
    cookiesPoliciesModalWhatIsCookieP1: 'Un rastro é un dato, ficheiro ou dispositivo de almacenamento e recuperación de datos (DARD) que o navegador web almacena de xeito automático no dispositivo (smartphone, tableta ou televisor conectado) do usuario cando este accede a páxinas web e contas de redes sociais ou recibe un correo electrónico.',
    cookiesPoliciesModalWhatIsCookieP2: 'Os rastros permiten almacenar e recuperar de xeito automatizado datos sobre o uso que se fixo dos citados medios dixitais e sobre o equipo ou dispositivo dende o que se accede a eles.',
    cookiesPoliciesModalWhatIsCookieP3: 'Un rastro son só datos que se almacenan no ordenador do usuario, polo que non se trata de software nin de fragmentos de código, só de datos. Por tanto, en principio os rastros non poden transmitir nin executar virus, nin instalar malware como troianos ou programas de espionaxe.',
    cookiesPoliciesModalCookieTypes: 'Que tipo de rastros hai na páxina web e a súa finalidade',
    cookiesPoliciesModalCookiesInstall: 'Que rastros se poden instalar na web de CertySign?',
    cookiesPoliciesModalCookiesInstallP1: 'O acceso á web de CertySign implica que se poidan instalar os rastros, propios ou de terceiros, que se detallan no seguinte cadro:',
    cookiesPoliciesModalBrowserConfigCookies: 'Como configurar os rastros no navegador',
    cookiesPoliciesModalBrowserConfigCookiesP1: 'Ten a opción de non recibir rastros ou de que lle comuniquen a fixación dos mesmos, o que deberá axustar na configuración do navegador; todos os rastros, excepto os de Flash, poden xestionarse dende o navegador, normalmente nos apartados «Opcións» ou «Preferencias».',
    cookiesPoliciesModalBrowserConfigCookiesP2: 'Pode desactivalos de todo, en cuxo caso o navegador non permitirá que se instale ningún (imposibilitando o uso de todas as funcións da páxina web), ou desactivar só os que procedan de determinadas webs, de xeito que poida gozar das funcións avanzadas das webs que respectan a privacidade dos datos, excluíndo só as que non o fagan.',
    cookiesPoliciesModalBrowserConfigCookiesP3: 'Mediante as seguintes ligazóns poderá acceder ás principais páxinas de soporte:',
    cookiesPoliciesModalBrowserConfigCookiesP4: 'Así mesmo, o usuario pode opoñerse ao uso de determinados rastros mediante os seguintes servizos:',
    cookiesPoliciesModalBrowserConfigCookiesLI1: 'Bloqueo de rastros en Chrome',
    cookiesPoliciesModalBrowserConfigCookiesLI2: 'Bloqueo de rastros en Safari',
    cookiesPoliciesModalBrowserConfigCookiesLI3: 'Bloqueo de rastros en Firefox',
    cookiesPoliciesModalBrowserConfigCookiesLI4: 'Bloqueo de rastros en Internet Explorer',
    cookiesPoliciesModalBrowserConfigCookiesLI5: 'http://www.criteo.com/deactivate-criteo-banners/',
    cookiesPoliciesModalBrowserConfigCookiesLI6: 'http://youronlinechoices.eu/',
    cookiesPoliciesModalBrowserConfigCookiesLI7: 'http://www.networkadvertising.org/choices/',
    cookiesPoliciesModalUnavailableCookies: 'Que ocorre se se inhabilitan os rastros?',
    cookiesPoliciesModalUnavailableCookiesP1: 'Se rexeita os rastros, poderá seguir navegando polo sitio web, aínda que o uso dalgúns servizos podería quedar limitado e, por tanto, a súa experiencia podería resultar menos satisfactoria.',
    cookiesPoliciesModalUnavailableCookiesP2: 'O rexeitamento, bloqueo ou non aceptación dos rastros descritos na presente política poden afectar a correcta navegación polos contidos do sitio web.',
    cookiesPoliciesModalAcceptCookies: 'Aceptación da política de rastros',
    cookiesPoliciesModalAcceptCookiesP1: 'Premendo o botón «ACEPTAR RASTROS», indica que acepta o uso dos rastros.',
    cookiesPoliciesModalAcceptCookiesP2: 'Non obstante, cada vez que inicie sesión, aparecerá a política de rastros na parte inferior das páxinas do portal co gallo de que sexa consciente dela.',
    cookiesPoliciesModalUpgradeCookies: 'Actualizamos a nosa política de rastros?',
    cookiesPoliciesModalUpgradeCookiesP1: 'É posible que a información fornecida na presente política de rastros poida sufrir modificacións por mor de actualizacións ou cambios no tratamento dos rastros ou da publicación de novas instrucións ou recomendacións por parte da Axencia Española de Protección de Datos.',
    cookiesPoliciesModalUpgradeCookiesP2: 'Por iso, recomendámoslle revisar esta política cada vez que acceda ao sitio web co gallo de estar ao día de como e para que usamos os rastros.',
    cookiesPoliciesModalRecipientCookies: 'Quen son os destinatarios da información e como afecta o uso de rastros á protección dos datos persoais?',
    cookiesPoliciesModalRecipientCookiesP1: 'Para máis información sobre o tratamento dos seus datos, pode consultar a ',
    cookiesPoliciesModalRecipientCookiesLinkCCOMMS: 'Política de protección de datos',
    cookiesPoliciesModalContactInfoCookies: 'Información de contacto',
    cookiesPoliciesModalContactInfoCookiesP1: 'Para calquera dúbida, aclaración ou suxestión sobre os rastros, debe contactar a través do correo electrónico ',
    cookiesPoliciesModalDatatableCookieType: 'Tipo de rastro',
    cookiesPoliciesModalDatatableTreatmentTerm: 'Prazo de tratamento',
    cookiesPoliciesModalDatatableTypeAnalytics: 'Analíticos',
    cookiesPoliciesModalDatatableTypeSelf: 'Propios',
    cookiesPoliciesModalDatatableTypeAnalyticsD: 'Cada vez que un usuario visita un servizo, unha ferramenta dun provedor externo xera un rastro analítico no seu ordenador. Este rastro, que só se xera na visita, utilizarao CCOMMS para, nas seguintes visitas, identificar de xeito anónimo o visitante.',
    cookiesPoliciesModalDatatableTypeAnalyticsF: 'Permitir a identificación anónima dos usuarios navegantes a través do rastro (identifica navegadores e dispositivos, pero non persoas) e, polo tanto, a contabilización aproximada do número de visitantes coas tendencias temporais. Identificar de xeito anónimo os contidos máis visitados e atractivos para os usuarios. Saber se o usuario que está accedendo é novo ou repite visita. Salvo que o usuario decida rexistrarse en CCOMMS, o rastro nunca irá asociado a ningún dato de carácter persoal que poida identificalo directamente. Os devanditos rastros só se utilizarán con fins estatísticos que axuden á optimización e mellora da experiencia dos usuarios no sitio.',
    cookiesPoliciesModalDatatableTypeAnalyticsT: 'Persistentes',
    cookiesPoliciesModalDatatableTypeSelfD: 'Este tipo de rastro permite ampliar a información que se amosa a cada usuario anónimo nos servizos de CCOMMS.',
    cookiesPoliciesModalDatatableTypeSelfF: 'Almacénanse, entre outros datos, a duración ou frecuencia da visualización dos distintos apartados, a interacción cos mesmos ou os patróns de navegación ou os comportamentos do usuario.',
    cookiesPoliciesModalDatatableTypeSelfT: 'Persistentes',
    eventInfo: 'Información do evento',
    manual: 'Manual',
    guia: 'Guía',
    supportLoggedPhonePrefferedMessage: '(Soporte de usuario que xa iniciou sesión - Preferido teléfono) ',
    supportLoggedNotPhoneMessage: 'Non marca teléfono',
    supportLoggedUserMessage: '(Soporte de usuario que xa iniciou sesión) ',
    supportNotLoggedUserWebMessage: '(Inicio de sesión - Soporte a través de Internet) O usuario solicita asistencia para recuperar o contrasinal',
    supportNotLoggedUserNotAvailableMessage: 'Non dispoñible',
    supportNotLoggedUserC2CMessage: '(Inicio de sesión - Soporte a través de C2C) O usuario solicita asistencia telefónica para recuperar o contrasinal',
    processA4NotSupported: 'O tamaño do documento non debe superar o formato A4, 389 páxinas nin os 15mb',
    cookiesGAMessage: 'Medición interna con Google Analytics. Estes rastros almacenan un identificador único para recoñecer o usuario cando no futuro volva visitar o sitio web.',
    cookiesGAFGenericMessage: 'Medición interna con Google Analytics.',
    cookiesCookieMessage: 'Rastro',
    cookiesNecessaryCookieDescription: 'Este rastro é necesario e utilízase para establecer a preferencia do usuario respecto á política de rastros.',
    documentHtmlRule: 'Tipo de ficheiro aceptado - HTML',
    rulesFilesSizePostal: 'O ficheiro debe pesar como máximo 15Mb',
    rulesFilesSizeEmail: 'O ficheiro debe pesar como máximo 14Mb',
    rulesFilesSizeMultiple: 'O tamaño máximo total do ficheiro é de 14Mb',
    rulesFilesSizeHtml: 'O ficheiro debe pesar como máximo {fileSize}Mb',
    rulesFilesExtensionHtml: 'O ficheiro debe ser un HTML',
    group_cannot_be_empty: 'O grupo :group_name: non pode estar baleiro',
    language: 'Idioma',
    defaultLanguage: 'Idioma por defecto',
    transactionDownloadPee: 'Ver PEE',
    transactionTracking: 'Seguimento do envío',
    documentOrder: 'Establecer a orde dos documentos',
    yes: 'Si',
    no: 'Non',
    notifications: 'Notificacións',
    activateNotifications: 'Activar',
    deactivateNotifications: 'Desactivar',
    selectCategoryNotifications: 'Seleccionar acción para notificacións',
    activateCategoryNotifications: 'Activar todas',
    deactivateCategoryNotifications: 'Desactivar todas',
    customCategoryNotifications: 'Personalizar',
    enabledNotifications: 'Notificacións activadas',
    disabledNotifications: 'Notificacións desactivadas',
    customNotifications: 'Notificacións personalizadas',
    selectNotifications: 'Seleccionar configuración de notificacións',
    enabledNotificationsDescriptionText: 'Cada evento realizado xerará unha notificación. Poderá elixir entre recibir un correo electrónico por cada unha delas ou un informe diario coas notificacións agrupadas.',
    disabledNotificationsDescriptionText: 'Non recibirá ningunha notificación relacionada cos estados dos envíos realizados.',
    customNotificationsDescriptionText: 'Poderá seleccionar procesos dos que queira recibir notificacións acerca dos seus estados e procesos dos que non.',
    enabledNotificationsConfigurationText: 'Configuración',
    enabledNotificationsConfigurationRealTime: 'Notificacións en tempo real',
    enabledNotificationsConfigurationGrouped: 'Notificacións agrupadas',
    selectEnabledNotifications: 'Seleccionar tipo de notificacións',
    enabledNotificationsRealTimeDescriptionText: 'Recibe un correo electrónico instantáneo por cada evento realizado.',
    enabledNotificationsGroupedDescriptionText: 'Recibirá un correo electrónico no horario seleccionado co informe de todos os eventos realizados durante o día anterior.',
    enabledNotificationsScheduleRangeDescriptionText: 'Notificar durante o seguinte horario.',
    enabledNotificationsScheduleDescriptionText: 'Notificar só no seguinte horario.',
    chooseNotificationsRange: 'Elixa como e cando quere recibir as notificacións.',
    enabledNotificationsCustomText: 'Personalización de notificacións',
    realTimeNotificationsDescription: 'As notificacións envíanse en tempo real. Por cada evento realizado, envíase un correo electrónico avisando do seu estado.',
    languageAndTimezone: 'Idioma',
    defaultTimeZone: 'Franxa horaria por defecto',
    user_process_notifications_updated: 'Notificacións actualizadas',
    changeSectionConfirmation: 'Cambios pendentes de gardar',
    changeSectionConfirmationText: 'Vostede realizou cambios que están pendentes de ser gardados. Se desexa gardalos agora, faga clic en',
    groupedNotificationsDescription: 'As notificacións envíanse mediante un informe adxunto que contén un listado con todas as transaccións e o último evento rexistrado en cada unha delas no horario seleccionado.',
    groupedNotificationsReportTime: 'A que hora quere recibir o informe',
    discard: 'Descartar',
    cost_center_updated: 'Centro de custo actualizado con éxito',
    loginInfo: 'Información de inicio de sesión',
    lastLoginSuccess: 'Último inicio de sesión',
    lastLoginError: 'Último intento de acceso erróneo',
    noRecord: 'Sen rexistro',
    charLimitLength: 'Campo debe ter {limit} caracteres ou menos',
    requiredLastName: 'Apelidos requiridos',
    optional: 'Opcional',
    availableVariables: 'Variables dispoñibles',
    twoFactorText: 'O usuario require dobre factor de autenticación. Para iniciar sesión introduza o código que poderá visualizar na súa aplicación de xestión de accesos multifactor.',
    mfa_configuration_required: 'O usuario require dobre factor de autenticación. Para configuralo, lea o QR desde a súa aplicación de xestión de accesos multifactor. Posteriormente, introduza o código no cadro inferior',
    code: 'Código',
    codeRequired: 'O código é requirido para iniciar sesión',
    mfa_code_invalid: 'O código non é correcto',
    communicationContent: 'Contido da comunicación',
    uploadFiles: 'Incluír documentos',
    modifyFiles: 'Modificar documentos',
    fileResume: '{nfiles} arquivo(s) ({size})MB en total',
    moreThan1City: 'O código postal corresponde a máis dunha poboación, consulte o despregable ou edite o campo manualmente',
    shipmentDataSectionDescription: 'Nesta sección engádense os contidos da comunicación. Os documentos deben ser de tipo PDF e tamaño A4',
    zipCodeFormat: 'Debe introducir un código postal válido',
    deleteRecipient: 'Eliminar este destinatario',
    mobileAddRecipientDescription: 'Máis dun destinatario?',
    errorFilesTotalSizeExceeded: 'O tamaño total dos ficheiros non pode superar os 15MB',
    fileExtensionNotAccepted: 'A extensión do ficheiro non é válida. (As extensións aceptadas son= {acceptedExtensions})',
    nameAndSurnameLength: 'O nome e os apelidos non poden superar os 99 caracteres',
    batchModeTitle: 'Envío por lotes',
    batchModeCSVStep: 'Destinatarios',
    batchModeAttachmentsStep: 'Ficheiros requiridos',
    batchModeDownloadSampleCSV: 'Descargar ficheiro exemplo',
    batchModeExtraCSVStepIntroduction: 'En primeiro lugar debe subir un ficheiro <strong>CSV</strong> ou <strong>XLS</strong> cos datos dos envíos. A continuación, pode descargar un ficheiro de exemplo que inclúe os campos necesarios.',
    batchModeExtraCSVStepIntroduction2: 'Unha vez teña preparado o ficheiro, pode engadilo facendo clic ou ben arrastrándoo na zona habilitada para iso. Lembre que o ficheiro <strong>non pode conter liñas baleiras nin erros</strong> en ningún dos destinatarios.',
    batchModeExtraCSVStepIntroduction3: 'Unha vez validada a estrutura do ficheiro, habilitarase o botón <strong>Continuar</strong> e poderá seguir co proceso.',
    batchModeExtraFilesStepIntroduction: 'Neste paso engadiranse os recursos necesarios para realizar o envío a cada destinatario.<br><br>Detectáronse un total de <b>{count}</b> ficheiros. Pode engadilos facendo clic ou ben arrastrándoo na zona habilitada para iso.<br><br>Recorde que a suma de ficheiros de cada envío non pode superar os <b>{fileSizeLimit} MB</b> e o envío ten un tamaño máximo xeral de <b>{totalSizeLimit} GB</b>.',
    extraFilesTableHeaderFileName: 'Nome do ficheiro',
    extraFilesTableHeaderStatus: 'Estado',
    extraFilesTableStatusNotFound: 'Pendente de subir',
    extraFilesTableStatusProcessing: 'Procesando',
    extraFilesTableStatusUploaded: 'OK',
    extraFilesTableStatusUploadedCorrectly: 'Procesado',
    extraFilesTableHeaderActions: 'Accións',
    batchModePreviewStep: 'Previsualizar e enviar',
    batchModeStep1RemoveFile: 'Borrar ficheiro',
    batchModeCSVTableTitle: 'O ficheiro <strong>{filename}</strong> contén <strong>{nOfRecipients}</strong> destinatarios.',
    batchModeCSVTableSubtitle: 'A continuación, dispón da previsualización dos primeiros destinatarios do ficheiro, faga clic en Ver máis para ampliar información.',
    batchModeCSVTableSubtitleNoData: 'O ficheiro seleccionado non contén ningún destinatario.',
    batchModeNoDataTitle: 'Ningún ficheiro seleccionado. Unha vez engadido dispoñerá dos primeiros destinatarios do ficheiro.',
    dropZoneText: 'Faga clic ou arrastre aquí os seus ficheiros',
    dropZoneTextSingle: 'Faga clic ou arrastre aquí o seu ficheiro',
    batchModeDialogTitle: 'Agregar destinatarios',
    batchModeDialogManualColTitle: 'De forma manual',
    batchModeDialogManualColDescription: 'Se desexa realizar un ou varios envíos de forma individual, elixa esta opción.',
    batchModeDialogBatchColTitle: 'Envío por lotes',
    batchModeDialogBatchColDescription: 'Se quere realizar envíos mediante un ficheiro csv ou xls con varios destinatarios e documentos dunha forma sinxela, elixa esta opción.',
    batchModeDialogBatchBtnManual: 'Envío individual',
    batchModeDialogBatchBtnBatch: 'Envío por lotes',
    batchModeResourceTableNOfFilesFound: '<b>{nOfRequiredFiles}</b> ficheiros pendentes de engadir',
    batchModeResourceTableAllFilesAdded: 'Engadíronse todos os ficheiros necesarios',
    // batchModeResourceTableStatus: 'Estado actual= <b>$currentFiles$</b>/$nOfRequiredFiles$',
    batchModeResourceTableExpandText: 'Faga clic para ver máis detalles',
    batchModeResourceTableFilenameText: 'Nome do ficheiro',
    batchModeResourceTableFoundOnNRows: 'destinatarios afectados',
    batchModeResourceTableErrorFilenameInvalid: 'Un ou máis dos ficheiros que se intentaron engadir non se corresponden cos ficheiros esperados.',
    batchModeResourceTableErrorFileSizeLimit: 'O tamaño do ficheiro é superior a {size}MB',
    batchModeResourceTableErrorTotalFileSizeLimit: 'O total dos ficheiros subidos non pode superar {size}GB',
    batchModeResourceTableInfoFileReplaced: 'Substituíuse o ficheiro {filename}.',
    seeMore: 'Ver máis',
    seeLess: 'Ver menos',
    pending: 'Pendente',
    batchModePreviewText: 'Unha vez sexa procesado poderá visualizar as transaccións na vista correspondente e recibirá un email coa información de cada envío, así como o identificador de transacción asociada a cada destinatario.',
    batchModePreviewText1: 'No caso de non se poder realizar algún dos envíos, no email especificarase cales deles non foron procesados co motivo do erro.',
    csvError: 'Non se puido procesar o ficheiro',
    csvErrorDetailStructure: 'A estrutura do ficheiro é errónea.',
    csvErrorDetailIncorrectFields: 'Os campos non coinciden cos datos necesarios do proceso',
    csvErrorDetailIncorrectExtensions: 'Hai extensións de ficheiro inválidas nas seguintes filas:',
    batchModeStep2SpaceLeft: 'Espazo usado',
    batchModeStep2PendingFiles: 'Ficheiros pendentes',
    launchDataKoBatchMode: 'Os datos proporcionados non son válidos',
    launchDataKoBatchModeStep1: 'Non se seleccionou ningún ficheiro',
    launchDataOkBatchModeStep1: 'A estrutura do ficheiro é correcta, pode continuar co proceso',
    launchDataKoBatchModeStep2: 'Engada os ficheiros necesarios',
    launchDataOkBatchModeStep2: 'Engadíronse os ficheiros necesarios, pode continuar co proceso',
    launchDataOkBatchMode: 'Os datos son correctos, pode continuar co proceso',
    batchModeProcessLaunchedStep1: 'Iniciando o proceso de rexistro',
    batchModeProcessLaunchedStep2: 'Subindo recursos necesarios para os envíos',
    batchModeProcessLaunchedStep3: 'Subindo ficheiro de destinatarios',
    batchModeProcessLaunchedStep4: 'Proceso de rexistro finalizado',
    transactionsProcessedBatchMode: 'Envío por lotes rexistrado correctamente',
    transactionProcessingBatchMode: 'Procesando envío por lotes...',
    transactionProcessingBatchModeError: 'Erro no procesamento do envío por lotes',
    transactionProcessingBatchModeErrorDescription: 'Aconteceu un erro no procesamento do lote, por favor póñase en contacto con soporte.',
    csvErrorDialogDescription: 'Lembre que debe subir un ficheiro <b>CSV</b> ou <b>XLS</b> e que o mesmo <strong>non pode conter liñas baleiras nin erros</strong> en ningún dos destinatarios.',
    extraFileError: 'Non se puido engadir o ficheiro',
    extraFileErrorDialogDescription: 'Recorde que o límite de tamaño por transacción é de <b>{fileSizeLimit} MB</b> e o envío completo do lote ten un tamaño máximo de <b>{totalSizeLimit} GB</b>.',
    extraFileErrorTransactionSizeLimit: 'Superouse o tamaño máximo para o envío nos seguintes rexistros= {rows}',
    htmlEditorInfoParagraph1: 'Neste paso, personalizarase o contido do email que se enviará a cada un dos destinatarios.',
    htmlEditorInfoParagraph3: 'Pode seleccionar un modo de edición:',
    htmlEditorInfoBullet1: '<span>Selección de padrón</span>. Seleccione un padrón e realice os axustes necesarios dende o editor.',
    htmlEditorInfoBullet2: '<span>HTML personalizado</span>. Suba un ficheiro HTML personalizado e realice os axustes necesarios dende o editor.',
    htmlEditorButtonUploadFile: 'SUBIR HTML',
    htmlEditorDragAndDrop: 'Faga clic aquí ou arrastre os ficheiros adxuntos ao email.',
    batchModeExtraCSVStepIntroductionVariables: 'Lembre que ten á súa disposición as seguintes variables para o seu uso nos campos de transacción:',
    batchModeContactId: 'Referencia de destinatario',
    batchModeContactName: 'Nome de destinatario',
    batchModeContactSurname: 'Apelidos de destinatario',
    htmlEditorTemplateSelected: 'Padrón seleccionado',
    htmlSubjectError: 'O asunto ten máis de <span>{subjectLimit}</span> caracteres',
    htmlEditorInfoParagraph4: 'Lembre que o tamaño máximo total dos ficheiros é <b>{totalSizeLimit} MB</b>.',
    backToProcess: 'Volver a procesos',
    infoAndHelp: 'Información e axuda',
    modelEmailHtmlTemplateSelector: 'Seleccionar modelo',
    modelEmailEmailBodyNotHtml: "O ficheiro seleccionado non é html",
    templateSelectorDefaultTemplate: 'Por defecto',
    templateSelectorCustomTemplate: 'Personalizada',
    templateSelectorNoLogoTemplate: 'Sen logotipo',
    filesExcedingSizeLimit: 'Ficheiros que superan o límite do tamaño',
    emailAttachmentsSectionTitle: 'Ficheiros anexos do correo electrónico',
    emailContent: 'Contido do correo electrónico',
    emailSubject: 'Asunto do correo electrónico',
    multipleFileExtensionError: "Hay uno o más ficheros con extensiones inválidas. (Las extensiones aceptadas son: {acceptedExtensions})" ,
    filePasswordProtectedOrDamage: "Ficheiro non válido. Comproba que o ficheiro non estea danado ou protexido por contrasinal e téntao de novo",
    backLostChangesHTMLText: "Se volve atrás, perderá os cambios no modelo de correo electrónico así como os ficheiros adxuntos. <br> Está seguro de <b>volver</b> ao paso anterior?",
    backLostChangesHTMLConfirmBack: "Volver ao paso anterior",
    backLostChangesHTMLConfirmContinue: "Continuar co envío"
}
